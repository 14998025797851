import {useState} from 'react'
import 'react-phone-input-2/lib/material.css'
import 'shared/ui/PhoneField/PhoneField.css'
import {TextField, InputAdornment} from '@mui/material'

export function PhoneField({InputProps, slotProps, onChange = () => {}, errorBorder = true, phone, ['data-cy']: dataCy='phone-input', ...other}) {
    const [isValidPhone, setIsValidPhone] = useState(!errorBorder || phone !== '')
    const [phoneState, setPhoneState] = useState(formatPhoneNumber(phone || ''))

    function formatPhoneNumber(value) {
        // delete NaN symbols
        const digits = value.replace(/\D/g, '')
        // formated string to (xxx) xxx-xxxx
        const formatted = digits.replace(/(\d{3})(\d{3})(\d{0,4})/, (match, p1, p2, p3) =>
            `(${p1}) ${p2}-${p3}`,
        )
        return formatted.substring(0, 14)
    }

    const handlePhoneChange = (event) => {
        const formattedValue = formatPhoneNumber(event.target.value)
        setPhoneState(formattedValue)
        const isValid = formattedValue.length === 14
        setIsValidPhone(isValid)
        onChange(isValid ? formattedValue : 'Invalid')
    }

    return (
        <div style={{display: 'grid', ...slotProps?.root?.sx, padding: '0 1px'}}>
            <TextField
                data-cy={dataCy}
                value={phoneState}
                onChange={handlePhoneChange}
                error={!isValidPhone}
                placeholder="(702) 123-4567"
                InputProps={{
                    startAdornment: InputProps?.startAdornment ? (
                        <InputAdornment position="start">
                            {InputProps.startAdornment}
                        </InputAdornment>
                    ) : null,
                    endAdornment: InputProps?.endAdornment ? (
                        <InputAdornment position="end">
                            {InputProps.endAdornment}
                        </InputAdornment>
                    ) : null,
                    readOnly: InputProps?.readOnly,
                }}
                fullWidth
                {...other}
            />
        </div>
    )
}
