import {create} from "zustand/index";
import {devtools} from "zustand/middleware";
import {api} from "../../shared/libs/Auth";

const sortGroups = (contacts) => {
    const groups = {};
    let noGroupContacts = [];

    contacts.forEach(contact => {
        if (contact.group) {
            const groupName = contact.group;
            groups[groupName] = groups[groupName] || [];
            groups[groupName].push(contact);
        } else {
            noGroupContacts.push(contact);
        }
    });

    return {
        sortedGroups: Object.keys(groups).sort().map(groupName => ({groupName, contacts: groups[groupName]})),
        noGroupContacts,
    };
};

export const useContactsStore = create(devtools((set, get) => ({
    contacts: [],
    newestContactsIds: [], //newly created contacts that have not been viewed yet
    groups: [],
    noGroupUsers: [],
    selectedContacts: {},
    isLoading: false,
    successMessage: false,

    fetchContacts: async () => {
        set({selectedContacts: {}});
        try {
            const response = await api.get('/contacts');
            const contacts = response.data;

            if (!Array.isArray(contacts)) {
                throw new Error('Data is not an array');
            }

            const {sortedGroups, noGroupContacts} = sortGroups(contacts);

            set({
                contacts,
                groups: sortedGroups,
                noGroupUsers: noGroupContacts,
            });
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    },

    fetchNewestContactsIds: () => {
        const successIdsString = sessionStorage.getItem('newestContacts')
        const successIds = JSON.parse(successIdsString)
        set({
            newestContactsIds: successIds,
        });
    },

    updateContactsData: (newData) => {
        if (!Array.isArray(newData)) {
            newData = [newData];
        }

        set((state) => {
            const updatedContacts = [...state.contacts];

            newData.forEach(newContact => {
                const index = updatedContacts.findIndex(contact => contact.id === newContact.id);
                if (index !== -1) {
                    // updating an existing contact
                    updatedContacts[index] = newContact;
                } else {
                    // add a new contact
                    updatedContacts.push(newContact);
                }
            });

            const {sortedGroups, noGroupContacts} = sortGroups(updatedContacts);

            return {
                contacts: updatedContacts,
                groups: sortedGroups,
                noGroupUsers: noGroupContacts,
            };
        });
    },

    selectContacts: (newContacts) => {
        set((state) => {
            return {
                selectedContacts: newContacts
            }
        })
    },

    selectAllContacts: () => {
        set((state) => {
            const allSelected = Object.keys(state.selectedContacts).length === state.contacts.length;

            if (allSelected) {
                return {selectedContacts: {}};
            } else {
                const newSelectedContacts = {};
                state.contacts.forEach(contact => {
                    newSelectedContacts[contact.id] = true;
                });

                return {selectedContacts: newSelectedContacts};
            }
        });
    },

    createContacts: async (validContacts, headers = false) => {
        const {updateContactsData} = get();
        try {
            const config = headers ? {headers: {'Content-Type': undefined}} : {};
            const responsePost = await api.post('/contacts', validContacts, config);
            updateContactsData(responsePost.data)
            return responsePost
        } catch (error) {
            console.error('Error updating contact:', error);
            throw error;
        }
    },

    editContact: async (contactId, newData) => {
        const {updateContactsData} = get();
        try {
            const response = await api.put(`/contacts/${contactId}`, newData);

            set((state) => ({
                contacts: state.contacts.map(contact =>
                    contact.id === contactId ? response.data : contact
                )
            }));
            updateContactsData(response.data)

        } catch (error) {
            console.error('Error updating contact:', error);
            throw error;
        }
    },

    deleteContactByID: async (contactId) => {
        const {deleteNewestContactIdFromSessionStorage} = get();
        try {
            await api.delete(`/contacts/${contactId}`).then(() => deleteNewestContactIdFromSessionStorage(contactId));
            set((state) => ({
                contacts: state.contacts.filter(contact => contact.id !== contactId),
                noGroupUsers: state.noGroupUsers.filter(contact => contact.id !== contactId),
                groups: state.groups.map(group => ({
                    ...group,
                    contacts: group.contacts.filter(contact => contact.id !== contactId),
                })),
            }));
        } catch (error) {
            console.error('Error deleting contact:', error);
        }
    },

    deleteSelectedContacts: async () => {
        const {selectedContacts, fetchContacts} = get();
        try {
            set({isLoading: true});

            const contactIdsToDelete = Object.keys(selectedContacts).filter(id => selectedContacts[id]);

            if (contactIdsToDelete.length === 0) {
                set({isLoading: false});
                return;
            }

            const deletePromises = contactIdsToDelete.map(id => api.delete(`/contacts/${id}`).catch(error => {
                console.error(`Error deleting contact with id ${id}:`, error);
            }));

            await Promise.all(deletePromises);
            await fetchContacts();

            set({selectedContacts: {}, isLoading: false});
        } catch (error) {
            console.error('Error in deleteContacts:', error);
            set({isLoading: false});
        }
    },

    deleteNewestContactIdFromSessionStorage: (id) => {
        const {newestContactsIds} = get();
        if (!newestContactsIds || newestContactsIds.length === 0) return

        const indexToRemove = newestContactsIds.indexOf(id)
        if (indexToRemove !== -1) {
            newestContactsIds.splice(indexToRemove, 1)
            const newSuccessIds = JSON.stringify(newestContactsIds)
            sessionStorage.setItem('newestContacts', newSuccessIds)
        }
    },

    addContactsIdsToSessionStorage: (contacts) => {
        if (!contacts && !contacts.id) return

        const existingIdsFromStorage = sessionStorage.getItem('newestContacts')
        const existingIds = existingIdsFromStorage ? JSON.parse(existingIdsFromStorage) : []

        const newSuccessIds = contacts.map((contact) => contact.id)

        const updatedIds = Array.from(new Set([...existingIds, ...newSuccessIds]))
        sessionStorage.setItem('newestContacts', JSON.stringify(updatedIds))
    },

    addNewGroup: (newGroupName) => {
        const {groups} = get();
        set({
            groups: [
                ...groups,
                {groupName: newGroupName, contacts: []}
            ]
        });
    },

    editGroupName: async (contactsToUpdate, newGroupName) => {
        const {updateContactsData} = get();
        await Promise.all(contactsToUpdate.map(async (contact) => {
            const updatedContact = {...contact, group: newGroupName};
            try {
                await api.put(`/contacts/${contact.id}`, updatedContact);
                updateContactsData(updatedContact);
            } catch (error) {
                console.error('Error updating contact group name:', error);
            }
        }));
    },

    deleteGroup: async (groupName) => {
        const {contacts, updateContactsData} = get();
        const contactsToUpdate = contacts.filter(contact => contact.group === groupName);

        set({isLoading: true});
        for (let contact of contactsToUpdate) {
            const updatedContact = {...contact, group: null};
            try {
                await api.put(`/contacts/${contact.id}`, updatedContact);
                updateContactsData(updatedContact)
            } catch (error) {
                console.error('Error updating contact:', error);
            }
        }
        set({isLoading: false});
    },
})))