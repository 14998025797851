import {create} from "zustand/index";
import {devtools} from "zustand/middleware";
import {api} from "../../shared/libs/Auth";


export const useNotificationsStore = create(devtools((set, get) => ({

    notifications: [],

    fetchNotifications: async () => {
        try {
            const response = await api.get('/notifications');
            set({ notifications: response.data });
            return response
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    },

    markAllNotificationsAsRead: async () => {
        try {
            await api.post('/notifications');
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    },
})))