export class StorageWrapper {
    constructor(type) {
        try {
            this._storage =
                type === 'local' ? window.localStorage : window.sessionStorage;
        } catch (e) {
            console.error(e)
        }
    }

    set (key, value) {
        if (!this._storage) return;

        try {
            const stringValue = JSON.stringify(value);
            this._storage.setItem(key, stringValue)
        } catch (e) {
            console.error(e)
        }
    }

    get (key) {
        if (!this._storage) return;

        try {
            const value = this._storage.getItem(key)
            if (value === null) {
                return;
            }
            return JSON.parse(value);
        } catch (e) {
            console.error(e)
        }
    }

    remove (key) {
        if (!this._storage) return;

        this._storage.removeItem(key)
    }

    clear () {
        if (!this._storage) return;

        this._storage.clear()
    }
}

export const localStorageWrapper = new StorageWrapper('local')
export const sessionStorageWrapper = new StorageWrapper('session')