import React from 'react'
import theme from 'app/Theme'

export function ErrorMessages({error = false, errorData}) {
    return (
        <div
            className={'paragraph'}
            style={{color: 'var(--palette-error-main)'}}
        >
            {error && Object.keys(errorData).map((key) => {
                const errorObject = errorData[key]
                return Array.isArray(errorObject) && typeof errorObject !== 'string'
                    ? errorObject.map((message) => (
                        <p>
                            {message}
                        </p>
                    ))
                    : <p>
                        {errorObject}
                    </p>
            })}
        </div>
    )
}

