import React from 'react'
import InfoCircleIcon from '../../assets/icons/InfoCircle'
import './InfoPanel.scss'

const InfoPanel = ({title, text, isOpen = false}) => (
    <div className={`infoPanel ${isOpen ? 'infoPanelVisible' : 'infoPanelHide'}`}>
        <div className={'infoPanelTitle'}>
            <InfoCircleIcon/>
            {title}
        </div>
        <div className={'infoPanelText'}>
            {text}
        </div>
    </div>
)

export default InfoPanel
