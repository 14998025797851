import React, {useEffect, useState} from 'react'
import {Button, Divider, IconButton, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import ArrowLeftSmallIcon from '../../../../shared/assets/icons/ArrowLeftSmall'
import CopyIcon from '../../../../shared/assets/icons/Copy'
import PenIcon from '../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../shared/assets/icons/Trash'
import MoreIcon from '../../../../shared/assets/icons/More'
import Urgency from './Urgency'
import {DefinitionRenderLarge} from '../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderLarge'
import {fetchWeatherDefinition} from '../../../alerts/api/FetchAlerts'
import {useActionItemsStore} from '../../../../app/store/ActionItemsStore'
import AgreeModal from '../../../../shared/ui/AgreeModal'

const filtersType = {
    weather_definitions: 'Weather definitions',
    reports: 'Reports',
}

const ActionItemDetails = ({actionItem}) => {
    const {
        deleteActionItem,
        openActionItem,
        toggleEditingActionItem,
        toggleDuplication,
    } = useActionItemsStore((state) => state)

    const [filter, setFilter] = useState(filtersType.weather_definitions)
    const [definition, setDefinition] = useState(null)
    const [deletingActionItem, setDeletingActionItem] = useState(null)

    useEffect(() => {
        fetchWeatherDefinition(actionItem.weather_definition.id).then((data) => {
            data.groups = data.rules
            setDefinition(data)
        })
    }, [])

    const handleDeleteActionItem = () => {
        setDeletingActionItem(actionItem)
    }

    const handleEditActionItem = () => {
        toggleEditingActionItem(actionItem)
    }

    const handleDuplicateActionItem = () => {
        toggleDuplication(true)
        toggleEditingActionItem(actionItem)
    }

    const approveDeleteActionItem = (ActionItemId, agree) => {
        if (agree) {
            deleteActionItem(ActionItemId)
            handleClose()
        }
        setDeletingActionItem(null)
    }

    const handleClose = () => {
        openActionItem(null)
    }

    return (
        <>
            <div className={'fullHeight fullWidth'}>
                <div
                    className={'settings'}
                    style={{height: '100%'}}
                >
                    <div
                        className={'settings-toolbar'}
                        style={{borderBottom: '1px solid var(--palette-grey-100)'}}
                    >
                        <IconButton
                            onClick={handleClose}
                            variant={'outlined'}
                            data-cy={'ai-left-button'}
                            size={'small'}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <h3>
                            {actionItem.name}
                        </h3>
                        <div style={{flexGrow: 1}}/>
                        <div style={{display: 'flex', gap: '10px'}}>
                            {<>
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    data-cy={'ai-details-copy-button'}
                                    startIcon={<CopyIcon/>}
                                    onClick={handleDuplicateActionItem}
                                >
                                    Copy
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    data-cy={'ai-details-edit-button'}
                                    startIcon={<PenIcon/>}
                                    onClick={handleEditActionItem}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    color={'error'}
                                    data-cy={'ai-details-delete-button'}
                                    startIcon={<TrashIcon/>}
                                    onClick={handleDeleteActionItem}
                                >
                                    Delete
                                </Button>
                            </>}
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '16px 24px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px', overflow: 'auto',
                        }}
                    >
                        <div className={'actionItemsContentWrapper'}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography style={{fontWeight: '500', fontSize: '16px'}}>
                                    Parameters
                                </Typography>
                                <IconButton
                                    data-cy={'more-button'}
                                    id="basic-button"
                                    variant={'outlined'}
                                    style={{width: '40px', height: '40px', boxShadow: '0px 1px 3px 0px #677A8E3D'}}
                                >
                                    <MoreIcon size={'small'}/>
                                </IconButton>
                            </div>
                            <div
                                className={'actionItemsContentWrapper'}
                                style={{padding: '16px'}}
                            >
                                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                        <Typography className={'actionItemsTitles'}>
                                            Category:
                                        </Typography>
                                        <div
                                            style={{
                                                borderRadius: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        color: 'var(--palette-grey-700)',
                                                        backgroundColor: 'var(--palette-grey-100)',
                                                        borderRadius: '8px', alignItems: 'center',
                                                        padding: '4px 8px', display: 'flex', gap: '3px',
                                                    }}
                                                >
                                                    <svg
                                                        className={'customSvg'}
                                                    >
                                                        <use
                                                            height="14"
                                                            width="14"
                                                            href={`${actionItem.category.icon.icon}#svg2`}
                                                        />
                                                    </svg>
                                                    {actionItem.category.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                        <Typography className={'actionItemsTitles'}>
                                            Urgency level:
                                        </Typography>
                                        <Urgency Urgency={actionItem.urgency}/>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                        <Typography className={'actionItemsTitles'}>
                                            Weather definition:
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: 'var(--palette-grey-900)',
                                            }}
                                        >
                                            {actionItem.weather_definition.name}
                                        </Typography>
                                    </div>
                                </div>

                                <Divider/>

                                {definition && <DefinitionRenderLarge definition={definition}/>}

                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                <Typography
                                    style={{fontWeight: '400', fontSize: '14px', color: 'var(--palette-grey-600)'}}
                                >
                                    Description of action item
                                </Typography>
                                <Typography
                                    style={{fontWeight: '400', fontSize: '14px', color: 'var(--palette-grey-900)'}}
                                >
                                    {actionItem.description}
                                </Typography>
                            </div>
                        </div>

                        <div className={'actionItemsContentWrapper'}>
                            <ToggleButtonGroup
                                style={{width: '400px'}}
                                value={filter}
                                exclusive
                            >
                                <ToggleButton
                                    variant={'secondary'}
                                    value={filtersType.weather_definitions}
                                    onClick={() => setFilter(filtersType.weather_definitions)}
                                >
                                    Weather definitions
                                </ToggleButton>
                                <ToggleButton
                                    variant={'secondary'}
                                    value={filtersType.reports}
                                    onClick={() => setFilter(filtersType.reports)}
                                    disabled
                                >
                                    Reports
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <div
                                className={'actionItemsContentWrapper'}
                                style={{padding: '16px', gap: '8px'}}
                            >
                                {filter === filtersType.weather_definitions && definition &&
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight: '500',
                                                    fontSize: '12px',
                                                    color: 'var(--palette-grey-600)',
                                                    textTransform: 'uppercase',
                                                    lineHeight: '40px',
                                                }}
                                            >
                                                Parameters
                                            </Typography>
                                            <IconButton
                                                data-cy={'more-button'}
                                                id="basic-button"
                                                variant={'outlined'}
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    boxShadow: '0px 1px 3px 0px #677A8E3D',
                                                }}
                                            >
                                                <MoreIcon size={'small'}/>
                                            </IconButton>
                                        </div>
                                        <DefinitionRenderLarge definition={definition}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deletingActionItem &&
                <AgreeModal
                    data={{
                        message: 'Are you sure you want to delete this action item?',
                        title: 'Delete action item',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: (agree) => approveDeleteActionItem(deletingActionItem.id, agree),
                    }}
                />
            }
        </>
    )
}

export default ActionItemDetails
