import maplibregl from "!maplibre-gl";
import {AbstractTextProduct} from '../AbstractTextProduct';
import stations from './stations.json';
import './SingleSiteRadar.scss'

export default class Nexrad extends AbstractTextProduct {

    constructor(opts) {
        opts = Object.assign({
            name: "nexrad",
            events: ['dragend', 'zoomend'],
            params: {}
        }, opts);
        super(opts);
        this.markers = []
        this.handleStationChange = this.handleStationChange.bind(this)
        this.handleActualDataNotFound = this.handleActualDataNotFound.bind(this)
        this.handleDataFound = this.handleDataFound.bind(this)

        this.options.map.on('stationStateChange', this.handleStationChange)
        this.options.map.on('actualDataNotFoundNexrad', this.handleActualDataNotFound)
        this.options.map.on('dataFoundNexrad', this.handleDataFound)
    }

    handleStationChange({stationName, selected}) {
        for (const marker of this.markers) {
            marker.setPopup(null)
            if (marker.station.name === stationName) {
                const markerElement = marker.getElement()
                markerElement.classList.remove('error')
                markerElement.classList.toggle('selected', selected)
                return
            }
        }
    }

    handleActualDataNotFound({site}) {
        for (const marker of this.markers) {
            if (marker.station.name !== site) {
                continue
            }

            const markerElement = marker.getElement()
            markerElement.classList.remove('selected')
            markerElement.classList.add('error')

            const popup = marker.getPopup()
            if (!popup) {
                const popup = new maplibregl.Popup({
                    offset: {
                        top: [0, 16],
                        bottom: [0, -16],
                        left: [16, 0],
                        right: [-16, 0],
                        center: [0, 0]
                    }
                })
                    .setHTML('<div>No data</div>')
                marker.setPopup(popup)
                    .togglePopup()
            } else {

            }
        }
    }

    handleDataFound({site}) {
        for (const marker of this.markers) {
            marker.setPopup(null)

            if (marker.station.name !== site) {
                continue
            }

            const markerElement = marker.getElement()
            markerElement.classList.remove('error')
            markerElement.classList.add('selected')
        }
    }

    enable() {
        super.enable()
        this.createMarkers()
    }

    disable() {
        super.disable();
        for (const marker of this.markers) {
            marker.remove()
        }
        this.markers = []
        this.options.map.off('stationStateChange', this.handleStationChange)
        this.options.map.off('actualDataNotFoundNexrad', this.handleActualDataNotFound)
        this.options.map.off('dataFoundNexrad', this.handleDataFound)
    }

    createMarkers() {
        const visibleStations = this.getVisibleStations()
        for (const visibleStation of visibleStations) {
            const htmlElement = this.createMarkerHtmlElement(visibleStation)
            const marker = this.createMarker(visibleStation, htmlElement)

            this.markers.push(marker)
        }
    }

    getVisibleStations() {
        // TODO: create only visible and new markers. If it's already exist, then do nothing
        const screenBounds = this.options.map.getBounds()
        const visibleStations = []
        for (const [name, lng, lat] of stations) {
            const station = {name, lng, lat}
            const stationPoint = new maplibregl.LngLat(lng, lat)
            const isStationVisible = true || screenBounds.contains(stationPoint)

            if (isStationVisible) {
                visibleStations.push(station)
            }
        }

        return visibleStations
    }

    createMarkerHtmlElement(station) {
        const htmlElement = document.createElement('div')
        htmlElement.setAttribute('data-cy', 'station')
        htmlElement.className = 'marker impmarker impmarker-header marker-body'
        htmlElement.innerHTML = `
            <div class="marker-label">
                ${station.name}
            </div>        
        `
        htmlElement.addEventListener('click', (event) => {
            event.stopPropagation()
            for (const marker of this.markers) {
                marker.setPopup(null)
                if (marker.station.name !== station.name) {
                    marker.getElement().classList.remove('selected', 'error')
                }
            }

            const hasError = htmlElement.classList.contains('error')
            const selected = hasError ? htmlElement.classList.toggle('error') : htmlElement.classList.toggle('selected')
            this.options.map.fire('stationClick', {
                station,
                selected,
                product: this
            })
        })

        return htmlElement
    }

    createMarker(station, element) {
        const marker = new maplibregl.Marker({
            element,
        }).setLngLat([station.lng, station.lat])
            .addTo(this.options.map)
        marker.station = station
        return marker
    }
}