import {isEmpty} from 'shared/libs/Utils'
import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {Button, Typography} from '@mui/material'
import {
    fetchAlertConfigurations,
    fetchConfigurationGroups,
    fetchWeatherDefinitions,
    deleteConfigurationGroup, fetchAlertConfigurationsExtended, togglePauseConfigurationGroup,
} from '../../../alerts/api/FetchAlerts'
import AlertsSettingsList from './AlertsSettingsList'
import AlertConfigurationDetails from '../../../../entities/alertConfig/ui/AlertConfigurationDetails'
import {Spacer} from 'shared/ui/Spacer'
import {SearchInput} from 'shared/ui/SearchInput'
import AgreeModal from 'shared/ui/AgreeModal'
import ItemsSelectTopBar from '../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import EmptyStatesAlerts from '../../../../shared/ui/emptyStates/EmptyStateAlerts'
import {LocationFilter} from 'widgets/location'
import {SeveritySelector} from '../../../../features/weatherDefinition/SeveritySelector'
import ProgressModal from '../../../../shared/ui/ProgressModal'
import {useLocationsStore} from '../../../../app/store/LocationsStore'

export function getConfigurations(weatherDefinitions, locations, alertConfigs, configGroups) {
    if (!weatherDefinitions?.length || !locations?.length || !alertConfigs?.length || !configGroups?.length) {
        return {}
    }

    const wds = {}
    weatherDefinitions.forEach((wd) => {
        wds[wd.id] = wd
        wd.configurations = {}
    })

    const locs = {}
    locations.forEach((loc) => {
        locs[loc.id] = loc
    })

    const cfgs = {}
    alertConfigs.forEach((cfg) => {
        cfgs[cfg.id] = cfg
    })

    const alrt = {}
    configGroups.forEach((relation) => {
        const config = cfgs[relation[1]]
        if (!config) return
        const definition = wds[config.weather_definition_id]
        const location = locs[config.location_id]
        if (!definition || !location) return
        if (!alrt[relation[0]]) {
            alrt[relation[0]] = {
                groupId: relation[0],
                locations: [],
                definition_id: definition.id,
                severity: definition.severity ?? 'Severe',
                type: definition.name,
            }
        }
        alrt[relation[0]].locations.push(location)
    })
    return alrt
}

const AlertsSettings = ({onChange, doupdate}) => {
    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const [alertConfigs, setAlertConfigs] = useState([])
    const [weatherDefinitions, setWeatherDefinitions] = useState([])
    const [configGroups, setConfigGroups] = useState([])
    const [severity, setSeverity] = useState('All')
    const [searchString, setSearchString] = useState('')
    const [openAlert, setOpenAlert] = useState()
    const [extendedAlertConfigs, setExtendedAlertConfigs] = useState([]) // contains paused alert data or not
    const [pauseAlertConfig, setPauseAlertConfig] = useState(null)
    const [restartAlertConfig, setRestartAlertConfig] = useState(null)
    const [progressModal, setProgressModal] = useState(false)

    //    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState({})
    const [deleteConfig, setDeleteConfig] = useState(false)
    const [selectedLocations, setSelectedLocations] = useState({})
    const [alertsToDelete, setAlertsToDelete] = useState({})
    const [agreeModal, setAgreeModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [newestAlertsIds, setNewestAlertsIds] = useState([])

    const allAlertsSelected = Object.keys(alertGroups).length > 0 && Object.keys(alertGroups).every((groupId) => alertsToDelete[groupId])
    const someAlertsSelected = Object.values(alertsToDelete).some((val) => val) && !allAlertsSelected

    if (doupdate && doupdate()) {
        setTimeout(() => {
            setUpdate(Date.now())
        }, 10)
    }

    useEffect(() => {
        //        fetchRealAlerts().then(data => {
        //            setAlerts(data)
        //        });
        fetchAlertConfigurations().then((data) => {
            setAlertConfigs(data)
        })
        fetchWeatherDefinitions().then((data) => {
            setWeatherDefinitions(data)
        })
        fetchConfigurationGroups().then((data) => {
            setConfigGroups(data)
        })
        fetchAlertConfigurationsExtended().then((data) => {
            setExtendedAlertConfigs(data)
        })
        fetchLocations().then((data) => {
            const locs = {}
            data.forEach((loc) => {
                locs[loc.id] = true
            })
            setSelectedLocations(locs)
        })
    }, [update])

    const idsToDelete = Object.entries(alertsToDelete).filter(([key, value]) => value).map(([key, value]) => +key)
    const alertsGroupsArray = Object.entries(alertGroups).map(([key, alert]) => (alert))
    const alertsToDeleteArray = alertsGroupsArray.filter((alert) => idsToDelete.includes(alert.groupId))

    const getNewestAlertsIdsFromSessionStorage = () =>{
        const successIdsString = sessionStorage.getItem('newestAlerts')
        const successIds = JSON.parse(successIdsString)
        setNewestAlertsIds(successIds)
    }

    const deleteAlertIdFromSessionStorage = (id) =>{
        if (!newestAlertsIds || newestAlertsIds.length === 0) return

        const indexToRemove = newestAlertsIds.indexOf(id)
        if (indexToRemove !== -1) {
            newestAlertsIds.splice(indexToRemove, 1)
            const newSuccessIds = JSON.stringify(newestAlertsIds)
            sessionStorage.setItem('newestAlerts', newSuccessIds)
        }
    }

    /**
     * function that takes action.
     * @param {string} action - type of action.
     * @param {Object} alert
     * @param {number} alert.definition_id
     * @param {number} alert.groupId
     * @param {array} alert.locations
     * @param {string} alert.severity
     * @param {string} alert.type
     * @param {boolean} value
     */
    const onAction = (action, alert, value = false) => {
        console.log('onAction', action, alert, value)
        console.log(alertGroups)
        if (action === 'edit') {
            const locations = {}
            alert.locations.forEach((loc) => {
                locations[loc.id] = true
            })
            setOpenAlert(null)
            setAlertsToDelete({})
            onChange('edit_alert', {
                back: true,
                locations: locations,
                definition: alert.definition_id,
                id: alert.groupId,
                report: alert.report,
            })
        } else if (action === 'duplicate') {
            const locations = {}
            setOpenAlert(null)
            setAlertsToDelete({})
            alert.locations.forEach((loc) => {
                locations[loc.id] = true
            })
            onChange('edit_alert', {
                back: true,
                locations: locations,
                definition: alert.definition_id,
                report: alert.report,
            })
        } else if (action === 'single_delete') {
            setDeleteConfig(alert)
        } else if (action === 'delete') {
            setAgreeModal(true)
        } else if (action === 'open') {
            setOpenAlert(alert)
            deleteAlertIdFromSessionStorage(alert.groupId)
            setAlertsToDelete({})
        } else if (action === 'select') {
            const toDel = alertsToDelete
            toDel[alert.groupId] = value
            for (const id in toDel) {
                if (toDel[id]) {
                    setAlertsToDelete({...toDel})
                    return
                }
            }
            setAlertsToDelete({})
        } else if (action === 'pause') {
            setPauseAlertConfig(alert)
        } else if (action === 'restart') {
            setRestartAlertConfig(alert)
        } else if (action === 'selectall') {
            const newAlertsToDelete = {}
            if (!allAlertsSelected) {
                Object.keys(alertGroups).forEach((groupId) => {
                    newAlertsToDelete[groupId] = true
                })
            }
            setAlertsToDelete(newAlertsToDelete)
        }
        // else if (action === 'selectall') {
        //     if (value) {
        //         Object.values(alertGroups).forEach(a => alertsToDelete[a.groupId] = value);
        //         setAlertsToDelete({...alertsToDelete});
        //     }
        //     else {
        //         setAlertsToDelete({});
        //     }
        // }
    }
    const doDeleteConfiguration = (agreed) => {
        if (agreed) {
            deleteConfigurationGroup(deleteConfig.groupId)
            deleteAlertIdFromSessionStorage(deleteConfig.groupId)
            const validConfigs = []
            for (const idx in alertGroups) {
                const alert = alertGroups[idx]
                if (alert.groupId === deleteConfig.groupId) continue
                validConfigs.push(alert)
            }
            setAlertGroups(validConfigs)
            setOpenAlert(false)
            setAlertsToDelete({})
        }
        setDeleteConfig(false)
    }

    const handlePauseOrRestartAlertConfig = (agreed, id, isRestart) => {
        if (agreed) {
            const data = JSON.stringify({enabled: isRestart})
            togglePauseConfigurationGroup(id, data).then(() => console.log('pause/restart alert'))
            onChange('pause/restart_alert')
        }
        setPauseAlertConfig(null)
        setRestartAlertConfig(null)
    }

    const deleteAlerts = (agree) => {
        if (agree) {
            setProgressModal(true)
            const al = []
            idsToDelete.forEach((groupId) => {
                al.push(deleteConfigurationGroup(groupId))
                deleteAlertIdFromSessionStorage(groupId)
            })
            Promise.all(al).then((results) => {
                console.log(results)
                const filteredAlertsGroupsArray = alertsGroupsArray.filter((alert) => !idsToDelete.includes(alert.groupId))
                const newAlertsGroups = {}
                filteredAlertsGroupsArray.forEach((alert) => {
                    newAlertsGroups[alert.groupId] = alert
                })
                setAlertGroups(newAlertsGroups)
                setAlertsToDelete({})
                setProgressModal(false)
            })
        }
        setAgreeModal(false)
    }

    const handleSeverityChange = (event) => {
        setSeverity(event.target.value)
    }

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim())
    }

    useEffect(() => {
        const alrt = getConfigurations(weatherDefinitions, locations, alertConfigs, configGroups)
        setAlertGroups(alrt)
    }, [weatherDefinitions, locations, alertConfigs, configGroups])

    useEffect(() => {
        getNewestAlertsIdsFromSessionStorage()
    }, [alertGroups])

    const s = searchString.toLocaleLowerCase()

    return (
        <>
            <Box
                className="AlertsSetting settings"
                sx={{display: openAlert ? 'none' : 'flex'}}
            >
                <Box
                    className={'settings-toolbar'}
                    style={{borderBottom: '1px solid var(--palette-grey-100)'}}
                >
                    <h3>
                        Alert configurations
                    </h3>
                    <SearchInput
                        placeholder='Search'
                        value={searchString}
                        onChange={searchStringChanged}
                    />
                    <LocationFilter
                        selectedLocations={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                    />

                    <SeveritySelector
                        severity={severity}
                        onChange={handleSeverityChange}
                    />

                    <Spacer/>

                    <Button
                        onClick={() => {
                            onChange('edit_alert', {back: true}); setAlertsToDelete({})
                        }}
                    >
                        New alert configuration
                    </Button>
                </Box>

                {/* <ItemsDelete onAction={(alert, action, value)=> onAction(action, alert, value)} visible={!isEmpty(alertsToDelete)}/> */}
                <ItemsSelectTopBar
                    onAction={(alert, action, value) => onAction(action, alert, value)}
                    visible={Object.keys(alertsToDelete).length > 0}
                    allSelected={allAlertsSelected}
                    indeterminate={someAlertsSelected}
                />

                {isEmpty(alertGroups)
                    ? <Box sx={{width: '100%', height: '100%'}}>
                        <EmptyStatesAlerts
                            title={'Configured alerts will appear here'}
                            text={'You will see alerts here once they are created'}
                        />
                    </Box>
                    : <Box>
                        {newestAlertsIds?.length > 0 && <>
                            <Box style={{fontSize: '14px', fontweight: '400', lineHeight: '20px', color: 'var(--palette-grey-500)', padding: '24px 0 0 24px'}}>
New alerts
                            </Box>
                            <AlertsSettingsList
                                alertGroups={Object.values(alertGroups).filter((alert)=>newestAlertsIds?.includes(alert.groupId))}
                                alertsToDelete={alertsToDelete}
                                onChange={onAction}
                                searchString={s}
                                severity={severity}
                                selectedLocations={selectedLocations}
                                extendedAlertConfigs={extendedAlertConfigs}
                                isNewAlert={true}
                            />
                        </>
                        }
                        <Box style={{fontSize: '14px', fontweight: '400', lineHeight: '20px', color: 'var(--palette-grey-500)', padding: '24px 0 0 24px'}}>
All alerts
                        </Box>
                        <AlertsSettingsList
                            alertGroups={Object.values(alertGroups).filter((alert)=>!newestAlertsIds?.includes(alert.groupId))}
                            alertsToDelete={alertsToDelete}
                            onChange={onAction}
                            searchString={s}
                            severity={severity}
                            selectedLocations={selectedLocations}
                            extendedAlertConfigs={extendedAlertConfigs}
                        />
                    </Box>
                }
            </Box>

            {
                openAlert &&
                <AlertConfigurationDetails
                    alert={openAlert}
                    extendedAlertConfig={Object.values(extendedAlertConfigs).filter((alert)=>alert.group_id === openAlert.groupId)}
                    onChange={(action) => {
                        if (action === 'single_delete') {
                            onAction('single_delete', openAlert)
                            return
                        }
                        onAction(action, openAlert)
                        setOpenAlert(null)
                    }}
                />
            }

            {pauseAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Pausing this alert will prevent it from being triggered, but will not remove previously triggered instances. Are you sure you want to pause "${pauseAlertConfig.type}" alert?`,
                        title: 'Pause alert',
                        agreeMsg: 'Pause',
                        mode: 'pausing',
                        agreeFunc: (agreed) => handlePauseOrRestartAlertConfig(agreed, pauseAlertConfig.groupId, false),
                    }}
                />
            }

            {restartAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want to restart "${restartAlertConfig.type}" alert?`,
                        title: 'Restart alert',
                        agreeMsg: 'Restart',
                        mode: 'restarting',
                        agreeFunc: (agreed) => handlePauseOrRestartAlertConfig(agreed, restartAlertConfig.groupId, true),
                    }}
                />
            }

            {deleteConfig &&
                <AgreeModal
                    data={{
                        message: `Deleting this alert will remove it permanently from the system, and delete all previously triggered instances of the alert as well. 
                        Are you sure you want to delete "${deleteConfig.type}" alert?`,
                        title: 'Delete alert configuration',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: doDeleteConfiguration,
                    }}
                />
            }
            {agreeModal &&
                <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                '&.MuiBox-root': {width: '100%'},
                            }}
                        >
                            <Typography
                                sx={{fontSize: '18px'}}
                            >
                                {(Object.keys(alertsToDelete).length === 1) ? 'Are you sure you want to delete this alert configuration?'
                                    : `Are you sure you want delete ${Object.keys(alertsToDelete).length} alerts configurations?`}
                            </Typography>
                            <Box sx={{maxHeight: '120px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', overflow: 'auto', gap: '0 16px'}}>
                                {alertsToDeleteArray.map((alert) =>
                                    (<span>
•
                                        {' '}
                                        {alert.type}
                                        <br/>
                                    </span>))}
                            </Box>
                        </Box>,
                        title: 'Delete multiple alerts configurations',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: deleteAlerts,
                    }}
                />
            }
            <ProgressModal visible={progressModal}/>
        </>
    )
}

export default AlertsSettings
