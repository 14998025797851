import {useCallback, useEffect, useState} from "react";
import {localStorageWrapper, sessionStorageWrapper} from "./storage";
import {useLatest} from "./useLatest";

export function useLocalStorageState(initialValue, key) {
    const getValue = () => {
        const storage = localStorage.getItem(key); // string || null

        if (storage) {
            return JSON.parse(storage); // '[]', '{}', ''
        }

        return initialValue;
    };

    const [value, setValue] = useState(getValue);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
}

export function useSessionStorageState(key, initialValue) {
    const getValue = () => {
        const storage = sessionStorage.getItem(key); // string || null

        if (storage) {
            return JSON.parse(storage); // '[]', '{}', ''
        }

        return initialValue;
    };

    const [value, setValue] = useState(getValue);

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
}