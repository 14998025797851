import {create} from "zustand/index";
import {devtools} from "zustand/middleware";
import {
    createDefaultMapView,
    createMapView, createTimelineView,
    deleteMapView, deleteTimelineView,
    editMapView, editTimelineView, fetchDefaultMapView,
    fetchMapViews,
    fetchTimelineViews
} from "../../pages/alerts/api/FetchAlerts";


export const useViewsStore = create(devtools((set, get) => ({
    mapViews: [],
    timelineViews: [],

    fetchDefaultMapView: async (products, locations, updateActiveProducts, updateActiveLocations, updateLocationHash) => {
        try {
            const [defaultMapView] = await fetchDefaultMapView();

            if (defaultMapView) {
                const { activeProducts, activeLocations, locationHash } = defaultMapView.data_json;

                const availableProducts = products
                    .filter((product) => activeProducts.includes(product.id) && product.available)
                    .map((product) => product.id);

                const existingActiveLocations = activeLocations.filter((id) =>
                    locations.some((location) => location.id === id)
                );

                updateActiveProducts(availableProducts);
                updateActiveLocations(existingActiveLocations);
                updateLocationHash(defaultMapView.data_json.locationHash || window.location.hash);
            } else {
                console.error('Default Map View is undefined');
            }
        } catch (error) {
            console.error('Default Map View error:', error);
        }
    },

    createDefaultMapView: async (defaultMapViewData) => {
        try {
            await createDefaultMapView(defaultMapViewData);
        } catch (error) {
            console.error('Error creating Default Map View:', error);
        }
    },

    fetchMapViews: async () => {
        await fetchMapViews().then((newViews) => {
            newViews.forEach((v) => {
                Object.assign(v, v.data_json)
                delete v.data_json
            })
            set((state) => ({
                mapViews: newViews
            }));
        })
    },

    createMapView: async (apiView) => {
        try {
            const view = await createMapView(apiView);
            if (view?.error) return;

            Object.assign(view, view.data_json);
            delete view.data_json;

            set((state) => ({
                mapViews: [...state.mapViews, view]
            }));
        } catch (error) {
            console.error("Failed to create map view:", error);
        }
    },

    editMapView: async (apiView) => {
        try {
            const view = await editMapView(apiView);
            if (view?.error) return;

            Object.assign(view, view.data_json);
            delete view.data_json;

            set((state) => ({
                mapViews: state.mapViews.map((v) =>
                    v.id === view.id ? view : v
                )
            }));
        } catch (error) {
            console.error("Failed to edit map view:", error);
        }
    },

    deleteMapView: async (id) => {
        const {mapViews} = get()

        try {
            await deleteMapView(id);
            const filteredViews = mapViews.filter((v) => v.id !== id)

            set((state) => ({
                mapViews: filteredViews
            }));
        } catch (error) {
            console.error("Failed to delete map view:", error);
        }
    },

    fetchTimelineViews: async () => {
        await fetchTimelineViews().then((newViews) => {
            newViews.forEach((v) => {
                Object.assign(v, v.data_json)
                delete v.data_json
            })
            set((state) => ({
                timelineViews: newViews
            }));
        })
    },

    createTimelineView: async (apiView) => {
        try {
            const view = await createTimelineView(apiView);
            if (view?.error) return;

            Object.assign(view, view.data_json);
            delete view.data_json;

            set((state) => ({
                timelineViews: [...state.timelineViews, view]
            }));
        } catch (error) {
            console.error("Failed to create map view:", error);
        }
    },

    editTimelineView: async (apiView) => {
        try {
            const view = await editTimelineView(apiView);
            if (view?.error) return;

            Object.assign(view, view.data_json);
            delete view.data_json;

            set((state) => ({
                timelineViews: state.timelineViews.map((v) =>
                    v.id === view.id ? view : v
                )
            }));
        } catch (error) {
            console.error("Failed to edit map view:", error);
        }
    },

    deleteTimelineView: async (id) => {
        const {timelineViews} = get()

        try {
            await deleteTimelineView(id);
            const filteredViews = timelineViews.filter((v) => v.id !== id)

            set((state) => ({
                timelineViews: filteredViews
            }));
        } catch (error) {
            console.error("Failed to delete map view:", error);
        }
    },
})))