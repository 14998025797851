import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React from 'react'

import theme from 'app/Theme'

export function Select(
    {
        defaultValue,
        value,
        placeholder = 'Select option',
        onChange,
        children,
        InputProps,
        MenuProps,
        renderValue,
        ...other
    }) {
    function handleChange(event) {
        onChange?.(event)
    }

    function canRenderPlaceholder() {
        return !value && !defaultValue
    }

    function canRenderStartAdornment() {
        return InputProps?.startAdornment
    }

    function StartAdornment() {
        let component
        if (canRenderStartAdornment() && canRenderPlaceholder()) {
            component = (
                <div className={'row gap8'}>
                    <div className={'paragraph'}>
                        {InputProps.startAdornment}
                    </div>
                    <div
                        className={'paragraph'}
                        style={{color: 'var(--palette-grey-400)'}}
                    >
                        {placeholder}
                    </div>
                </div>
            )
        } else if (canRenderStartAdornment()) {
            component = (
                <div className={'paragraph'}>
                    {InputProps.startAdornment}
                </div>
            )
        } else if (canRenderPlaceholder()) {
            component = (
                <div
                    className={'paragraph'}
                    style={{color: 'var(--palette-grey-400)'}}
                >
                    {placeholder}
                </div>
            )
        } else {
            return null
        }

        return (
            <InputAdornment
                position={'start'}
                onClick={(event) => event.preventDefault()}
            >
                {component}
            </InputAdornment>
        )
    }

    return (
        <TextField
            select
            defaultValue={defaultValue}
            value={value}
            onChange={handleChange}
            InputProps={{
                startAdornment: StartAdornment(),
            }}
            SelectProps={{
                renderValue: renderValue,
                MenuProps: MenuProps,
            }}
            {...other}
        >
            {children}
        </TextField>
    )
}
