import React, {useEffect, useState} from 'react'

import theme from 'app/Theme'
import {DatePicker} from 'shared/ui/datePickers/DatePicker'
import {RangePickersDay} from 'shared/ui/datePickers/RangePickersDay'
import {RangeTextField} from 'shared/ui/datePickers/RangeTextField'

export function DateRangePicker(
    {
        defaultValue = [],
        onChange = () => {
        },
        onCancel,
        slotProps,
        format = "MMM DD 'YY — MMM DD 'YY",
        placeholder = format,
        label = '',
        ...other
    }) {
    const [dateRange, setDateRange] = useState([])

    useEffect(() => {
        setDateRange(defaultValue)
    }, [])

    function handleDateChange(day) {
        if (!day) {
            return handleClear()
        }

        // TODO: Date range shouldn't be updated when month/year changed
        if (dateRange.length === 2) {
            return setDateRange([day])
        }

        const newRange = [...dateRange, day]
        if (newRange.length === 2) {
            newRange.sort((a, b) => a.diff(b))
        }

        setDateRange(newRange)
    }

    const handleClear = () => {
        setDateRange([])
    }

    function handleAccept() {
        onChange(dateRange)
    }

    return (
        <DatePicker
            data-cy={'date-range-picker'}
            value={dateRange?.[dateRange.length - 1]}
            format={format}
            onChange={handleDateChange}
            onAccept={handleAccept}
            onClear={handleClear}

            slots={{
                day: RangePickersDay,
                textField: RangeTextField,
            }}
            slotProps={{
                textField: {
                    dateRange: dateRange,
                    dayFormat: "MMM DD 'YY",
                    placeholder: placeholder,
                    sx: {
                        '& .MuiInputBase-root': {
                            pointerEvents: 'none',
                        },
                        '& .MuiInputBase-root button': {
                            pointerEvents: 'all',
                        },
                    },
                    ...(label && {
                        InputProps: {
                            startAdornment:
                                <div
                                    className={'paragraph'}
                                    style={{
                                        textWrap: 'nowrap',
                                        whiteSpace: 'nowrap',
                                        color: 'var(--palette-grey-400)',
                                    }}
                                >
                                    {label}
                                </div>,
                        },
                    }),
                    ...(slotProps?.textField),
                },
                day: {
                    dayRange: dateRange,
                },
            }}
            {...other}
        />
    )
}
