import React, {useEffect, useState} from 'react'
import {Box, Button, ClickAwayListener, IconButton, MenuItem, Typography} from '@mui/material'
import {Spacer} from 'shared/ui/Spacer'
import DesktopIcon from '../../../shared/assets/icons/Desktop'
import './Map.css'
import ClearButton from '../../../features/map/ui/clearButton/ClearButton'
import PlusIcon from '../../../shared/assets/icons/Plus'
import DesktopLoadIcon from '../../../shared/assets/icons/DesktopLoad'
import ArrowRightSmallIcon from '../../../shared/assets/icons/ArrowRightSmall'
import DragVerticalIcon from '../../../shared/assets/icons/DragVertical'
import PenIcon from '../../../shared/assets/icons/Pen'
import TrashIcon from '../../../shared/assets/icons/Trash'
import theme from '../../../app/Theme'
import DesktopSaveIcon from '../../../shared/assets/icons/DesktopSave'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import SaveViewModal from '../../../shared/ui/SaveViewModal'
import AgreeModal from '../../../shared/ui/AgreeModal'
import {useViewsStore} from '../../../app/store/ViewsStore'

export default function MapMenu({
    onSwitchProduct,
    onSwitchLocation,
    onSwitchLocationHash,
    activeProducts,
    activeLocations,
    onClearLocations,
    onClearLayers,
    onClearPqPopups,
    onAllClear,
    locationHash,
}) {
    const {
        toggleEditingLocation,
    } = useLocationsStore((state) => state)

    const {
        mapViews,
        fetchMapViews,
        createMapView,
        editMapView,
        deleteMapView,
    } = useViewsStore((state) => state)

    const [showMenu, setShowMenu] = useState(false)
    const [showLoadMenu, setShowLoadMenu] = useState(false)
    const [saveViewModal, setSaveViewModal] = useState(false)
    const [editViewModalID, setEditViewModalID] = useState(null)
    const [deleteViewModalID, setDeleteViewModalID] = useState(null)

    useEffect(() => {
        fetchMapViews()
    }, [])

    const loadMenuItems = mapViews || []
    const loadMenuItemsObj = {}
    for (const idx in mapViews) {
        loadMenuItemsObj[mapViews[idx].id] = mapViews[idx]
    }

    const checkBoxesInSaveModal = [
        {key: 'position', text: 'Map position'},
        {key: 'layers', text: 'Selected layers'},
        {key: 'locations', text: 'Selected locations'},
    ]
    const newView = {
        id: '',
        title: '',
        includes: {position: true, layers: true, locations: true},
        data: {
            position: locationHash,
            layers: activeProducts,
            locations: activeLocations,
        },
    }

    const editedViews = Object.fromEntries( // Old views with values from newView
        Object.keys(loadMenuItemsObj).map((id) => [
            id,
            {
                ...newView,
                id: loadMenuItemsObj[id].id,
                title: loadMenuItemsObj[id].title,
            },
        ]),
    )

    const handleSaveNewView = (view) => {
        setSaveViewModal(false)
        setEditViewModalID(null)

        if (!view) return

        const apiView = {
            name: view.title,
            order: view.order,
            data_json: view,
        }
        delete view.name
        delete view.order

        if (!view.id) {
            delete view.id
            createMapView(apiView)
        } else {
            apiView.id = view.id
            delete view.id
            editMapView(apiView)
        }
    }

    const handleDeleteView = (agree, id) => {
        setDeleteViewModalID(null)

        const view = loadMenuItemsObj[id]

        if (!view) return
        if (!loadMenuItemsObj[view.id]) return
        if (agree) {
            deleteMapView(view.id)
        }
    }

    const hideMenu = () => {
        setShowMenu(false)
        setShowLoadMenu(false)
    }

    const onClickLoadView = (e) => {
        hideMenu()
        const id = e.target.parentElement.dataset.id
        const view = loadMenuItemsObj[id]
        if (!view) return // WL-624
        const newSelectedLayers = view['includes'].layers ? view.data.layers : activeProducts
        const newSelectedLocation = view['includes'].locations ? view.data.locations : activeLocations
        const newMapPosition = view['includes'].position ? view.data.position : locationHash
        onSwitchProduct(newSelectedLayers)
        onSwitchLocation(newSelectedLocation)
        onSwitchLocationHash(newMapPosition)
    }

    const onClickSaveView = (e) => {
        e.stopPropagation()
        setSaveViewModal(true)
    }

    const onClickEditView = (e) => {
        e.stopPropagation()
        setEditViewModalID(e.currentTarget.parentElement.dataset.id)
    }

    const onClickDeleteView = (e) => {
        e.stopPropagation()
        setDeleteViewModalID(e.currentTarget.parentElement.dataset.id)
    }

    return (
        <Box className={'mapMenu toolbar padding'}>
            <h3>
                Map
            </h3>
            <Spacer/>
            <Box style={{position: 'relative'}}>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    startIcon={<DesktopIcon/>}
                    onClick={() => {
                        setShowMenu(!showMenu)
                    }}
                >
                    Views
                </Button>
                {showMenu
                    && <ClickAwayListener onClickAway={hideMenu}>
                        <Box
                            style={{
                                position: 'absolute',
                                left: '-150px', top: '50px',
                                zIndex: '1', width: '200px',
                                backgroundColor: '#ffffff',
                                padding: '10px 15px',
                                cursor: 'default',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px', boxShadow: '0px 1px 3px rgba(103, 122, 142, 0.24)',
                                borderRadius: '8px',
                                border: '0',
                            }}
                        >
                            <MenuItem
                                onClick={(e) => {
                                    setShowMenu(!showLoadMenu)
                                    onClickSaveView(e)
                                }}
                            >
                                <DesktopSaveIcon size={'small'}/>
                                <Box className={'spacer'}>
                                    Save view
                                </Box>
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setShowLoadMenu(!showLoadMenu)
                                }}
                            >
                                <DesktopLoadIcon size={'small'}/>
                                <Box className={'spacer'}>
                                    Load view
                                </Box>
                                <ArrowRightSmallIcon size={'small'}/>
                            </MenuItem>
                            {showLoadMenu
                                && <Box
                                    className='timeline-shadow'
                                    id='timeline-load-view-menu'
                                    sx={{
                                        position: 'absolute',
                                        left: 205,
                                        top: 39,
                                        minWidth: '220px',
                                        margin: '0',
                                        backgroundColor: '#ffffff',
                                        cursor: 'default',
                                        padding: '10px 15px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px',
                                        fontSize: '14px',
                                    }}
                                   >
                                    {mapViews.length ? (loadMenuItems.map((item) => (
                                        <MenuItem
                                            key={item.id}
                                            data-id={item.id}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.children[2].style.opacity = '1'
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.children[2].style.opacity = '0'
                                            }}
                                            onClick={onClickLoadView}
                                        >
                                            <DragVerticalIcon size={'small'}/>
                                            <Box className={'spacer'}>
                                                {item.title}
                                            </Box>
                                            <Box
                                                sx={{opacity: '0'}}
                                                data-id={item.id}
                                            >
                                                <IconButton
                                                    size={'tiny'}
                                                    onClick={onClickEditView}
                                                >
                                                    <PenIcon size={'small'}/>
                                                </IconButton>
                                                <IconButton
                                                    size={'tiny'}
                                                    onClick={onClickDeleteView}
                                                >
                                                    <TrashIcon
                                                        size={'small'}
                                                        sx={{color: 'var(--palette-error-main)'}}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </MenuItem>
                                    ))) : 'No views have been saved yet'}
                                </Box>
                            }
                        </Box>
                    </ClickAwayListener>
                }
            </Box>
            <ClearButton
                clearLocations={onClearLocations}
                clearLayers={onClearLayers}
                allClear={onAllClear}
                clearPq={onClearPqPopups}
            />
            <Button
                variant={'contained'}
                color={'primary'}
                style={{width: '150px'}}
                startIcon={<PlusIcon size={'medium'}/>}
                onClick={() => toggleEditingLocation({})}
            >
                Add location
            </Button>
            {saveViewModal
                && <SaveViewModal
                    headerText='Save current view'
                    saveText='Save view'
                    saveFunc={handleSaveNewView}
                    view={newView}
                    checkBoxes={checkBoxesInSaveModal}
                   />
            }
            {editViewModalID
                && <SaveViewModal
                    headerText='Edit view'
                    saveText='Save changes'
                    saveFunc={handleSaveNewView}
                    view={editedViews[editViewModalID]}
                    checkBoxes={checkBoxesInSaveModal}
                   />
            }
            {deleteViewModalID
                && <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                '&.MuiBox-root': {width: '100%'},
                            }}
                                 >
                            <Typography sx={{fontSize: '18px'}}>
                                {`Are you sure you want to delete "${loadMenuItemsObj[deleteViewModalID].title}" view?`}
                            </Typography>
                        </Box>,
                        title: 'Delete view',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: (agree) => handleDeleteView(agree, deleteViewModalID),
                    }}
                   />
            }
        </Box>
    )
}
