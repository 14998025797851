import {Typography} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';

import 'shared/ui/PasswordStrengthIndicator/PasswordStrengthIndicator.css'
import React from "react";
import {CheckCircle} from 'shared/ui/CheckCircle'

export function PasswordStrengthIndicator(
    {
        minLength = false,
        hasNumber = false,
        hasSpecialChar = false,
        hasUppercaseChar = false,
        sx,
        ...other
    }) {

    return (
        <div data-cy='password-strength-indicator' className={'indicatorContainer'} style={sx}>
            <div className={'column'}>
                <FormControlLabel
                    checked={minLength}
                    label={<Typography variant={'CheckCircle'}>8 Characters minimum</Typography>}
                    control={<CheckCircle size={'small'}/>}
                />
                <FormControlLabel
                    checked={hasSpecialChar}
                    label={<Typography variant={'CheckCircle'}>One special character</Typography>}
                    control={<CheckCircle size={'small'}/>}
                />
            </div>
            <div className={'column'} style={{justifyContent: 'flex-start'}}>
                <FormControlLabel
                    checked={hasNumber}
                    label={<Typography variant={'CheckCircle'}>One number</Typography>}
                    control={<CheckCircle size={'small'}/>}
                />
                <FormControlLabel
                    checked={hasUppercaseChar}
                    label={<Typography variant={'CheckCircle'}>One uppercase character</Typography>}
                    control={<CheckCircle size={'small'}/>}
                />
            </div>
        </div>
    )
}