import {create} from "zustand"
import {devtools} from "zustand/middleware";
import {fetchUser, patchUser} from "../../pages/alerts/api/FetchAlerts";

const DEFAULT_MESSAGES = {
    forecastAppears: '{{alert_name}} alert at {{location}} beginning {{start_time}} ({{timezone}}) {{start_date}}\n{{email_template}} {{severity}} Forecast Alert: {{alert_name}} expected\nLocation: {{location}}\nDates and Times:\n{{date_value_verbose}} {{ellipsis}}\nAlert Issued: {{issue_start_date}}, at {{issue_start_time}} {{timezone}}',
    forecastUpdates: '{{alert_name}} forecast at {{location}} on {{start_time}} {{start_date}} was just updated',
    weatherEventStarted: '{{severity}} live alert: {{alert_name}} at {{location}} has just started',
    customTime: '{{severity}} forecast alert: {{alert_name}} at {{location}} on {{start_time}} {{start_date}}',
};

export const useUserStore = create(devtools((set, get) => ({
    user: {
        username: '',
        email: '',
        phone: '',
        status: 'Active subscription',
        allow_sms_notifications: false,
        allow_email_notifications: false,
    },

    fetchUser: async () => {
        const [response] = await fetchUser()
        response.default_notification_messages = validateDefaultMessages(response)
        response.username = validateUsername(response)

        set((state) => ({
            user: {
                ...state.user,
                ...response,
                status: state.user.status,
            }
        }))

        return response
    },

    patchUser: async (data) => {
        const response = await patchUser(data)
        set((state) => ({
            user: {
                ...response,
                username: response.username,
                status: state.user.status,
            }
        }))

        return response
    }
})))

function validateUsername({username}) {
    if (username.match(/\s\w*/)[0] === ' ') {
        return username.replace(/\s/g, '')
    }
    else {
        return username
    }
}

function validateDefaultMessages({default_notification_messages}) {
    if (default_notification_messages) {
        return JSON.parse(default_notification_messages);
    } else {
        return {
            fc_appears: DEFAULT_MESSAGES.forecastAppears,
            fc_updates: DEFAULT_MESSAGES.forecastUpdates,
            we_starts: DEFAULT_MESSAGES.weatherEventStarted,
            custom_time: DEFAULT_MESSAGES.customTime,
        };
    }
}