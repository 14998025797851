import {
    Box,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography, IconButton, ToggleButton, ToggleButtonGroup,
} from '@mui/material'
import ArrowDownSmallIcon from 'shared/assets/icons/ArrowDownSmall'
import {isEmpty} from 'shared/libs/Utils'
import React, {useState, useEffect} from 'react'
import LocationLine from 'entities/location/ui/LocationLine'
import {EditGroupName} from './EditLocationGroupName'
import PenIcon from '../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../shared/assets/icons/Trash'
import {SearchInput} from 'shared/ui/SearchInput'
import AgreeModal from 'shared/ui/AgreeModal'
import ItemsSelectTopBar from '../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import EmptyStateLocations from '../../../../shared/ui/emptyStates/EmptyStateLocations'
import './SavedLocationsSetting.css'
import {CheckBox} from '../../../../shared/ui/CheckBox'
import ProgressModal from '../../../../shared/ui/ProgressModal'
import ListIcon from '../../../../shared/assets/icons/List'
import MapMarkerIcon from '../../../../shared/assets/icons/MapMarker'
import {MapLibreLocationStatic} from '../../../../features/map/ui/mapLibreLocation/MapLibreLocation'
import SelectLocationModal from './SelectLocationModal'
import {useLocationsStore} from '../../../../app/store/LocationsStore'

export default function SavedLocationsSetting({}) {
    const {
        locations,
        selectedLocations,
        locationGroups,
        isLoading,
        fetchLocations,
        selectLocations,
        deleteSelectedLocations,
        deleteLocationByID,
        editLocation,
        toggleEditingLocation,
    } = useLocationsStore((state) => state)

    const [isOpenEditLocationGroup, setIsOpenEditLocationGroup] = useState(false)
    const [isOpenDeleteLocationGroup, setIsOpenDeleteLocationGroup] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [accordion, setAccordion] = useState({})
    const [deleteLocationsModal, setDeleteLocationsModal] = useState(false)
    const [viewMode, setViewMode] = useState('list')
    const [openSelectLocationModal, setOpenSelectLocationModal] = useState(false)

    const allLocationsSelected = locations.length > 0 && locations.every((location) => selectedLocations[location.id])
    const someLocationsSelected = Object.values(selectedLocations).some((val) => val) && !allLocationsSelected

    useEffect(() => {
        fetchLocations()
    }, [isOpenEditLocationGroup, isOpenDeleteLocationGroup])

    useEffect(() => {
        if (locationGroups.length > 0 && isEmpty(accordion)) {
            const firstGroupName = locationGroups[0].group
            setAccordion({[firstGroupName]: true})
        }
    }, [locationGroups])

    const idsToDelete = Object.entries(selectedLocations).filter(([key, value]) => value).map(([key, value]) => +key)
    const locationsArray = Object.entries(locations).map(([key, location]) => (location))
    const locationsToDeleteArray = locationsArray.filter((location) => idsToDelete.includes(location.id))

    const handleActionsWithTopBar = (location, action, value) => {
        console.log('onAction', action, location, value)
        if (action === 'delete') {
            setDeleteLocationsModal(true)
        }
    }

    const deleteLocationGroup = async (agree) => {
        if (!agree) {
            setIsOpenDeleteLocationGroup(false)
            return
        }

        const state = isOpenDeleteLocationGroup
        selectLocations({})

        try {
            if (!state.delete_locations) { // deleting group without locations
                await Promise.all(
                    state.locations.map((loc) =>
                        editLocation(loc.id, {location_group: ''}),
                    ),
                )
            } else { // deleting group with locations
                await Promise.all(
                    state.locations.map((loc) =>
                        deleteLocationByID(loc.id),
                    ),
                )
            }
        } catch (error) {
            console.error('Error during deletion:', error)
        } finally {
            setIsOpenDeleteLocationGroup(false)
        }
    }

    const searchStringChanged = ({target}) => {
        const s = target.value.trim()
        if (searchString.length === 0 && s.length !== 0) {
            const a = {}
            for (const g in locationGroups) {
                a[locationGroups[g].group] = true
            }
            setAccordion(a)
        } else if (searchString.length !== 0 && s.length === 0) {
            // if all groups are open, close them all (???)
            let cntclosed = 0
            for (const g in accordion) {
                if (!accordion[g]) {
                    cntclosed++
                    break
                }
            }
            if (cntclosed === 0) {
                setAccordion({})
            }
        }
        setSearchString(target.value.trim())
    }
    const accordionChanged = (group) => (event, isExpanded) => {
        const a = {...accordion}
        a[group] = isExpanded
        setAccordion(a)
    }

    const deleteLocations = (agree) => {
        if (agree) {
            deleteSelectedLocations()
        }
        setDeleteLocationsModal(false)
    }

    const handleChangeDeleteLocations = () => {
        setIsOpenDeleteLocationGroup({
            group: isOpenDeleteLocationGroup.group,
            delete_locations: !isOpenDeleteLocationGroup.delete_locations,
            locations: isOpenDeleteLocationGroup.locations,
        })
    }

    const getGroupLocationsID = (group) => locationGroups.find((groupItem) => groupItem.group === group)
        ?.locations.map((location) => location.id) || []

    const allSelected = (group) => {
        const groupLocationsID = getGroupLocationsID(group)
        return groupLocationsID.every((locationId) => selectedLocations[locationId])
    }

    const allIndeterminate = (group) => {
        const groupLocationsID = getGroupLocationsID(group)

        if (!groupLocationsID.length) return true

        return groupLocationsID.some((locationId) => selectedLocations[locationId] && !allSelected(group))
    }

    const handleSelectAll = (group) => {
        const groupLocationsID = getGroupLocationsID(group)
        const allSelected = groupLocationsID.every((locationId) => selectedLocations[locationId])
        const updatedSelectedLocations = {...selectedLocations}

        groupLocationsID.forEach((locationId) => {
            if (allSelected) {
                delete updatedSelectedLocations[locationId]
            } else {
                updatedSelectedLocations[locationId] = true
            }
        })
        selectLocations(updatedSelectedLocations)
    }

    const handleChangeViewMode = (event, newVal) => {
        if (!newVal) {
            return
        }
        setViewMode(newVal)
    }

    const handleOpenSelectLocationModal = (location) => {
        if (!location) return
        setOpenSelectLocationModal(location)
    }

    const handleEditSelectLocation = (location) => {
        toggleEditingLocation(location)
        setViewMode('list')
        setOpenSelectLocationModal(false)
    }

    const renderListOrMap = () => {
        if (viewMode === 'list') {
            return (locationGroups.map((locationGroup) => {
                let locations = locationGroup.locations
                locations.sort((a, b) => a.label.localeCompare(b.label))
                if (s) {
                    locations = locations.filter((l) => l.label.toLocaleLowerCase().indexOf(s) >= 0)
                    if (locations.length === 0) {
                        return undefined
                    }
                }
                return (
                    <Box className={'savedLocationsList'}>
                        <Accordion
                            expanded={accordion[locationGroup.group] === true}
                            onChange={accordionChanged(locationGroup.group)}
                            key={locationGroup.group}
                            sx={{
                                boxShadow: 'inherit',
                                alignItems: 'center',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                border: '1px solid var(--color-coding-grayscale-100, #F0F2F5)',
                                background: 'var(--color-coding-grayscale-0, #FFF)',
                                '&:before': {
                                    display: 'none',
                                },
                                '&:first-of-type': {
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                },
                                '& .Mui-expanded': {
                                    background: 'var(--palette-grey-background)',
                                    borderBottomLeftRadius: '0px',
                                    borderBottomRightRadius: '0px',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <IconButton
                                        size='tiny'
                                        variant='outlined'
                                    >
                                        <ArrowDownSmallIcon size={'small'}/>
                                    </IconButton>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    width: 'auto',
                                    height: '46px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: 'var(--color-coding-grayscale-0, #FFF)',
                                    borderRadius: '8px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        alignSelf: 'stretch',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <CheckBox
                                        checked={allSelected(locationGroup.group)}
                                        indeterminate={allIndeterminate(locationGroup.group)}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleSelectAll(locationGroup.group)
                                        }}
                                    />
                                    <Box sx={{height: '24px'}}>
                                        <Typography
                                            sx={{
                                                color: 'var(--color-coding-grayscale-900, #171E27)',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '24px',
                                                letterSpacing: '0.16px',
                                            }}
                                        >
                                            {locationGroup.group || 'Ungrouped locations'}
                                            {' '}
(
                                            {locationGroup.locations.length}
)
                                        </Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: 'var(--palette-grey-background)',
                                    padding: '0px 16px',
                                }}
                            >
                                {
                                    locations.map((location) => (
                                        <Box sx={{marginBottom: '8px'}}>
                                            <LocationLine
                                                location={location}
                                                selected={selectedLocations && selectedLocations[location.id]}
                                            />
                                        </Box>
                                    ))
                                }
                            </AccordionDetails>

                            {locationGroup.group &&
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            padding: '0px 16px 16px',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            gap: '8px',
                                            alignSelf: 'stretch',
                                            background: 'var(--palette-grey-background)',
                                        }}
                                    >
                                        <Button
                                            className={'regular'}
                                            variant={'outlined'}
                                            color={'primary'}
                                            size={'small'}
                                            startIcon={<PenIcon size={'small'}/>}
                                            onClick={() => {
                                                setIsOpenEditLocationGroup({
                                                    original: locationGroup.group,
                                                    new: locationGroup.group,
                                                })
                                            }}
                                        >
                                            Edit group name
                                        </Button>
                                        <Button
                                            className={'regular'}
                                            variant={'outlined'}
                                            color={'error'}
                                            size={'small'}
                                            startIcon={<TrashIcon size={'small'}/>}
                                            onClick={() => setIsOpenDeleteLocationGroup({
                                                group: locationGroup.group,
                                                locations: locationGroup.locations,
                                                delete_locations: false,
                                            })}
                                        >
                                            Delete group
                                        </Button>
                                    </Box>
                            }
                        </Accordion>
                    </Box>
                )
            })
            )
        }

        if (viewMode === 'map') {
            return (
                <Box style={{width: '100%', height: '100%', padding: '0px 24px'}}>
                    <MapLibreLocationStatic
                        coordinates={[-97, 38]}
                        locations={locations}
                        onSelectLocation={handleOpenSelectLocationModal}
                    />
                </Box>
            )
        }
    }

    const s = searchString.toLocaleLowerCase()

    return (
        <Box className="SavedLocationsSettings settings">
            <Box
                className="settings-toolbar"
                style={{borderBottom: '1px solid var(--palette-grey-100)'}}
            >
                <h3>
                    Saved locations
                </h3>
                <SearchInput
                    placeholder='Search'
                    value={searchString}
                    onChange={searchStringChanged}
                />
                <ToggleButtonGroup
                    style={{height: '100%'}}
                    value={viewMode}
                    exclusive
                    size={'small'}
                    onChange={handleChangeViewMode}
                >
                    <ToggleButton
                        style={{height: '100%', padding: '10px'}}
                        variant={'secondary'}
                        value={'list'}
                        size={'small'}
                    >
                        <ListIcon size={'small'}/>
                    </ToggleButton>
                    <ToggleButton
                        style={{height: '100%', padding: '10px'}}
                        variant={'secondary'}
                        value={'map'}
                        size={'small'}
                    >
                        <MapMarkerIcon size={'small'}/>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Button
                    data-cy={'new-location-button'}
                    sx={{marginLeft: 'auto'}}
                    onClick={() => {
                        toggleEditingLocation({})
                        setViewMode('list')
                    }}
                >
New location
                </Button>
            </Box>

            <ItemsSelectTopBar
                onAction={handleActionsWithTopBar}
                visible={Object.values(selectedLocations).some((value) => value)}
                allSelected={allLocationsSelected}
                indeterminate={someLocationsSelected}
            />

            <Box
                style={{
                    overflow: 'auto',
                    padding: '16px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    height: '100%',
                }}
            >
                {isEmpty(Object.values(locations))
                    ? <Box sx={{width: '100%', height: '100%'}}>
                        <EmptyStateLocations
                            title={'Locations will appear here'}
                            text={'You will see locations here once they are created'}
                        />
                    </Box> : renderListOrMap()
                }
            </Box>

            {isOpenEditLocationGroup &&
                <EditGroupName
                    editGroupState={isOpenEditLocationGroup}
                    onChange={(state) => {
                        if (state.new) {
                            const newIndex = {[state.new]: true}
                            setAccordion({...accordion, ...newIndex})
                        }
                        setIsOpenEditLocationGroup(false)
                    }}
                />
            }

            {isOpenDeleteLocationGroup &&
                <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                '&.MuiBox-root': {maxWidth: 'fit-content'},
                            }}
                        >
                            {isOpenDeleteLocationGroup.group ? (
                                <Typography sx={{fontSize: '18px'}}>
                                    Are you sure you want to delete "
                                    {isOpenDeleteLocationGroup.group}
" location group?
                                </Typography>
                            ) : (
                                <Typography sx={{fontSize: '18px'}}>
                                    Are you sure you want to delete
                                    {' '}
                                    {isOpenDeleteLocationGroup.locations.length > 1
                                        ? `${isOpenDeleteLocationGroup.locations.length} saved locations`
                                        : `"${isOpenDeleteLocationGroup.locations[0].label}" saved location`}
?
                                </Typography>
                            )}
                            {isOpenDeleteLocationGroup.group &&
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '15px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleChangeDeleteLocations}
                                >
                                    <CheckBox
                                        checked={isOpenDeleteLocationGroup.delete_locations}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                    <Typography sx={{fontSize: '16px'}}>
Also delete locations in this group
                                    </Typography>
                                </Box>}
                        </Box>,
                        title: `Delete ${isOpenDeleteLocationGroup.group ? 'group' : isOpenDeleteLocationGroup.locations.length > 1 ? 'locations' : 'location'}`,
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: deleteLocationGroup,
                    }}
                />
            }
            {openSelectLocationModal &&
                <SelectLocationModal
                    editableLocation={true}
                    location={openSelectLocationModal}
                    onEdit={(location) => handleEditSelectLocation(location)}
                    onDelete={() => setOpenSelectLocationModal(false)}
                    onCancel={() => setOpenSelectLocationModal(false)}
                />}
            {deleteLocationsModal &&
                <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                '&.MuiBox-root': {width: '100%'},
                            }}
                        >
                            <Typography
                                sx={{fontSize: '18px'}}
                            >
                                {(Object.keys(selectedLocations).length === 1) ? 'Are you sure you want to delete this location?' : `Are you sure you want delete ${Object.keys(selectedLocations).length} locations?`}
                            </Typography>
                            <Box
                                sx={{
                                    maxHeight: '120px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    overflow: 'auto',
                                    gap: '0 16px',
                                }}
                            >
                                {locationsToDeleteArray.map((location) =>
                                    (<span>
•
                                        {' '}
                                        {location.label}
                                        <br/>
                                    </span>))}
                            </Box>
                        </Box>,
                        title: 'Delete multiple location groups?',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: deleteLocations,
                    }}
                />
            }
            <ProgressModal visible={isLoading}/>
        </Box>
    )
}
