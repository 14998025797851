import {Box} from "@mui/material";
import React from "react";
import {availableToRenderDefinition} from "./availableToRenderDefinition";
import theme from "../../../app/Theme";
import {Operand} from "./Operand";

export function DefinitionRenderCompact({definition}) {
		if (!availableToRenderDefinition(definition)) {
				return <Box></Box>
		}
		return (
				<Box className={'column gap8'} style={{width: '100%'}}>
						{definition.groups.map((group, index) =>
								<RenderGroupCompact group={{
										...group,
										isFirst: !index,
								}}/>
						)}
				</Box>
		)
}

function RenderGroupCompact({group}) {
		return (
				<div id={'groupCompact'} className={'row gap8'} style={{flexWrap: 'wrap'}}>
						{group.lines.map((line, index) =>
								<RenderLineCompact
										line={{
												...line,
												isFirst: !index,
												isLast: index === group.lines.length - 1,
										}}
										group={group}
								/>
						)}
				</div>
		)
}

function RenderLineCompact({line, group}) {
		return (
				<Box id={'lineCompact'} className={'row gap8 spacer'}>
						{!group.isFirst && line.isFirst &&
								<label className={'medium spacer'}>
										{group.union}
								</label>
						}
						{!line.isFirst &&
								<label className={'medium spacer'} style={{marginRight: 'auto'}}>
										{line.union}
								</label>
						}
						{line.isFirst &&
								<div style={{fontSize: '24px', fontWeight: 300, color: 'var(--palette-grey-500)'}}>
										(
								</div>
						}
						<Operand line={line} group={group}/>
						<div style={{
								fontSize: '24px',
								fontWeight: 300,
								color: line.isLast ? 'var(--palette-grey-500)' : 'transparent'
						}}>
								)
						</div>
				</Box>)
}