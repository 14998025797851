import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Dialog,
    Divider,
    IconButton,
} from '@mui/material'
import CloseIcon from '../assets/icons/Close'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {ReactComponent as LocationsRectangle1} from 'shared/assets/svg/confirmationPopUp/LocationsRectangle.svg'
import {ReactComponent as ConfirmationLocationsMap} from 'shared/assets/svg/confirmationPopUp/ConfirmationLocationsMap.svg'
import {ReactComponent as LocationsPin} from 'shared/assets/svg/confirmationPopUp/LocationsPin.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import MapPinIcon from '../assets/icons/MapPin'
import MapMarkerIcon from '../assets/icons/MapMarker'

export default function LocationsConfirmationModal({
    locations,
    agreeFunc,
}) {
    const [isSingleLocation] = useState(locations.length === 1)

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '504px',
                gap: '24px',
                padding: '12px 16px',
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    {`Well done, your ${isSingleLocation ? 'location is' : 'locations are'} created!`}
                </Box>
                <IconButton
                    variant={'outlined'}
                    onClick={agreeFunc}
                    size={'small'}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </Box>
            <Box style={{position: 'relative', height: '80px', overflow: 'hidden'}}>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: 'absolute', left: '-27px', top: '35px'}}>
                    <LocationsRectangle1/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '9px', top: '0px'}}>
                    <ConfirmationLocationsMap/>
                </Box>
                <Box sx={{position: 'absolute', right: '102px', top: '4px'}}>
                    <LocationsPin/>
                </Box>
            </Box>
            {isSingleLocation ? (
                <Box
                    style={{
                        height: '48px',
                        padding: '8px 16px',
                        border: '1px solid var(--palette-grey-100)',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{fontSize: '16px', fontWeight: '500', color: 'var(--palette-grey-900)', display: 'flex', alignItems: 'center', gap: '8px'}}>
                        <MapPinIcon size={'small'}/>
                        {locations[0].label}
                    </div>
                    <div style={{fontSize: '16px', fontWeight: '500', color: 'var(--palette-grey-900)'}}>
                        {locations[0].location_group}
                    </div>
                </Box>
            ) : (<Box className={'fullWidth'}>
                <Accordion
                    className={'fullWidth'}
                    variant={'light'}
                >
                    <AccordionSummary
                        variant={'light'}
                    >
                        <Box className={'row gap4'}>
                            <div style={{fontSize: '14px', fontWeight: '500', color: 'var(--palette-grey-900)', display: 'flex', gap: '4px', alignItems: 'center'}}>
                                {isSingleLocation ? <MapPinIcon size={'small'}/> : <MapMarkerIcon size={'small'}/>}
                                Locations (
                                {locations.length}
                                )
                            </div>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails
                        className={'gap8 locationGroup'}
                        variant={'light'}
                    >
                        <Box
                            style={{
                                display: 'flex', gap: '8px', flexWrap: 'wrap', maxHeight: '90px', overflow: 'auto',
                            }}
                        >
                            {locations?.map((location) => (
                                <Chip
                                    icon={<MapPinIcon size={'small'}/>}
                                    label={location.label}
                                    size={'medium'}
                                    key={location.id}
                                />
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>)}
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                You can overview location data in the list of Saved Locations. To manage data for a location, go to their individual settings page.
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <Divider/>
                <Button
                    style={{fontWeight: '400', height: '40px', width: '100%'}}
                    data-cy={'wd-success-creation-okay-button'}
                    onClick={agreeFunc}
                >
                    Got it
                </Button>
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
