import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {deleteLocation, fetchRealLocations, postLocation, putLocation} from "../../pages/alerts/api/FetchAlerts";
import {api} from "../../shared/libs/Auth";

const calcGroups = (locations) => {
    if (!locations) return;

    const groups = {};
    locations.forEach((location) => {
        if (!groups[location.location_group]) groups[location.location_group] = [];
        groups[location.location_group].push(location);
    });

    const groupsList = [];
    let ungrouped = [];
    for (let key in groups) {
        if (!key) {
            ungrouped = groups[key];
            continue;
        }
        groupsList.push({
            group: key,
            locations: groups[key],
        });
    }
    groupsList.sort((a, b) => a.group.localeCompare(b.group));
    if (ungrouped.length) {
        groupsList.push({ group: '', locations: ungrouped });
    }

    return groupsList;
};

export const useLocationsStore = create(devtools((set, get) => ({

    locations: [],
    locationGroups: [],
    selectedLocations: {},
    editingLocation: null,
    isLoading: false,

    fetchLocations: async () => {
        set({ selectedLocations: {} });
        const response = await fetchRealLocations();
        const locationGroups = calcGroups(response.location);
        set((state) => ({
            locations: response.location,
            locationGroups: locationGroups,
        }));

        return response.location;
    },

    selectLocations: (newLocations) => {
        set((state) => {
            return {
                selectedLocations: newLocations
            }
        })
    },

    selectAllLocations: () => {
        set((state) => {
            const allSelected = state.locations.length === Object.keys(state.selectedLocations).length;

            const updatedSelectedLocations = allSelected
                ? {}
                : state.locations.reduce((acc, location) => {
                    if (!state.selectedLocations[location.id]) {
                        acc[location.id] = location;
                    }
                    return acc;
                }, {...state.selectedLocations});

            return {
                selectedLocations: updatedSelectedLocations
            };
        });
    },

    toggleEditingLocation: (location) => { // pass an empty object to start creating a new location
        set((state) => ({
            editingLocation: location ? {
                location: {
                    options: {
                        title: location.label,
                        radius: 0,
                        group: location.location_group,
                        id: location.id,
                    },
                    coordinates: location.coordinates,
                }
            } : null
        }));
    },

    editLocationGroupName: async (locationIds, newData) => {
        try {
            const idsToUpdate = Array.isArray(locationIds) ? locationIds : [locationIds];

            await Promise.all(idsToUpdate.map(locationId => {
                return putLocation(locationId, newData).then(() => {
                    set((state) => ({
                        locations: state.locations.map((loc) =>
                            loc.id === locationId ? {...loc, location_group: newData.location_group} : loc
                        )
                    }));
                });
            }))
        } catch (error) {
            console.error('Error updating locations:', error);
            throw error;
        }
        set({
            selectedLocations: {}
        });
    },

    editLocation: async (locationId, updatedData) => {
        const { fetchLocations } = get();
        set({ isLoading: true });

        try {
            const response = await putLocation(locationId, updatedData);
            fetchLocations();

            return response;
        } catch (error) {
            console.error('Error updating location:', error);
            throw error;
        } finally {
            set({
                selectedLocations: {},
                isLoading: false
            });
        }
    },

    createLocation: async (data, headers = false) =>{
        const {fetchLocations} = get();

        try {
            const response = headers? await api.post('/locations', data, {
                headers: {
                    'Content-Type': undefined,
                },
            }) : await postLocation(data)
            fetchLocations()
            return response
        } catch (error) {
            console.error('Error updating locations:', error);
            throw error;
        }
    },

    deleteLocationByID: async (locationID) => {
        const { locations } = get();
        set({ isLoading: true });

        try {
            await deleteLocation(locationID);
            const updatedLocations = locations.filter((loc) => loc.id !== locationID);
            const locationGroups = calcGroups(updatedLocations);
            set({
                locations: updatedLocations,
                locationGroups: locationGroups
            });
        } catch (error) {
            console.error('Error during deletion:', error);
        } finally {
            set({
                selectedLocations: {},
                isLoading: false
            });
        }
    },

    deleteSelectedLocations: async () => {
        const { selectedLocations } = get();
        set({ isLoading: true });

        const idsToDelete = Object.keys(selectedLocations)
            .filter(key => selectedLocations[key])
            .map(Number);

        try {
            await Promise.all(idsToDelete.map(deleteLocation));

            set(state => {
                const updatedLocations = state.locations.filter(
                    location => !idsToDelete.includes(location.id)
                );

                const updatedLocationGroups = state.locationGroups
                    .map(group => ({
                        ...group,
                        locations: group.locations.filter(
                            location => !idsToDelete.includes(location.id)
                        )
                    }))
                    .filter(group => group.locations.length);

                return {
                    locations: updatedLocations,
                    locationGroups: updatedLocationGroups,
                    selectedLocations: {},
                };
            });

        } catch (error) {
            console.error("Error during deletion:", error);
        } finally {
            set({ isLoading: false });
        }
    },
})))