import CloseIcon from 'shared/assets/icons/Close'
import MapPinIcon from 'shared/assets/icons/MapPin'
import MoreIcon from 'shared/assets/icons/More'
import React, {useState} from 'react'
import {Box, Typography, IconButton, Menu, MenuItem, FormControl, Button, Select, Input} from '@mui/material'
import theme from 'app/Theme'
import ModalComponent from 'shared/ui/ModalComponent'
import locationImage from '../../../shared/assets/png/locationImage.png'
import {CheckBox} from 'shared/ui/CheckBox'
import ArrowRightIcon from '../../../shared/assets/icons/ArrowRight'
import PenIcon from '../../../shared/assets/icons/Pen'
import TrashIcon from '../../../shared/assets/icons/Trash'
import 'entities/location/ui/LocationLine.css'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import AgreeModal from '../../../shared/ui/AgreeModal'
import {EntityGroupSelectorPopup} from '../../../features/entityGroupSelector/EntityGroupSelectorPopup'

export default function LocationLine({location, selected}) {
    const {selectedLocations, locationGroups, selectLocations, deleteLocationByID, toggleEditingLocation} = useLocationsStore((state) => state)

    const [openEditLocationModal, setOpenLocationModal] = useState(false)
    const [openDeleteLocationModal, setOpenDeleteLocationModal] = useState(false)
    const [moveLocationToGroup, setMoveLocationToGroup] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleMove = () => {
        setMoveLocationToGroup({
            locations: [location],
            original: location.location_group,
            new: location.location_group,
        })
        setAnchorEl(null)
    }
    const handleDelete = (agreed) => {
        if (agreed) {
            deleteLocationByID(location.id)
        }
        setOpenDeleteLocationModal(false)
        setAnchorEl(null)
    }
    const handleEdit = () => {
        toggleEditingLocation(location)
        setAnchorEl(null)
    }

    const formatCoordinates = (coords) => {
        const points = []
        if (coords.length >= 2) return coords.join(',')
        coords.forEach((c) => {
            points.push(c[0] + ',' + c[1])
        })
        return points.join(';')
    }

    const handleCheck = () => (event) => {
        event.stopPropagation()
        const updatedToDelete = {...selectedLocations}
        updatedToDelete[location.id] = !selected
        selectLocations(updatedToDelete)
    }

    function LocationDisplay({location}) {
        const city = location?.address?.city
        const region = location?.address?.region?.name
        const country = location?.address?.country?.name

        const parts = [
            city && `${city},`,
            region && `${region},`,
            country,
        ].filter(Boolean).join(' ')

        return (
            <div className="paragraph">
                {parts}
            </div>
        )
    }

    return (
        <Box
            className="location-line row gap8"
            sx={{
                width: 'auto',
                height: '88px',
                padding: '12px 16px',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '8px',
                border: '1px solid var(--color-coding-grayscale-100, #F0F2F5)',
                background: 'var(--color-coding-grayscale-0, #FFF)',
                margin: '0',
                transition: '0.3s',
                boxShadow: selected ? '0px 2px 6px 0px rgba(103,122,142,0.24)' : 'none',
            }}
        >
            <CheckBox
                checked={selected || false}
                onClick={handleCheck()}
            />
            <img
                src={locationImage}
                style={{width: '72px', height: '64px', marginLeft: '5px'}}
                alt={'locationImage'}
            ></img>
            <div className={'locationDetails'}>
                <div className={'subtitle medium'}>
                    {location.label}
                </div>
                <div className={'row gap8'}>
                    <LocationDisplay location={location}/>
                    <div
                        className={'paragraph'}
                        style={{color: 'var(--palette-grey-500)'}}
                    >
                        {formatCoordinates(location.coordinates)}
                    </div>
                </div>
            </div>
            <div className={'fullHeight'}>
                <div className={'row gap8'}>
                    <MapPinIcon size={'small'}/>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        size={'tiny'}
                        variant={'outlined'}
                    >
                        <MoreIcon size={'small'}/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem
                            key={'move to group'}
                            onClick={handleMove}
                        >
                            <ArrowRightIcon size={'small'}/>
                            Move to group...
                        </MenuItem>
                        <MenuItem
                            key={'edit'}
                            onClick={handleEdit}
                        >
                            <PenIcon size={'small'}/>
                            Edit
                        </MenuItem>
                        <MenuItem
                            key={'delete'}
                            color={'error'}
                            onClick={() => setOpenDeleteLocationModal(true)}
                        >
                            <TrashIcon size={'small'}/>
                            Delete
                        </MenuItem>
                    </Menu>
                </div>
            </div>

            <ModalComponent visible={openEditLocationModal}>
                <Box sx={{display: 'inline-flex', padding: '24px', flexDirection: 'column', gap: '24px'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        }}
                    >
                        <Typography variant='h5'>
Edit location
                        </Typography>
                        <IconButton onClick={() => setOpenLocationModal(false)}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{width: '1061px', height: '500px'}}>
                        MAP
                        <Box
                            sx={{
                                width: '400px',
                                padding: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '16px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                            }}
                        >
                            <Input
                                type='text'
                                placeholder='Custom area'
                            />
                            <Box>
                                <Button>
                                    {' '}
Draw shape
                                </Button>
                                <Button>
Import shape
                                </Button>
                            </Box>
                            <FormControl>
                                <Select>

                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box>
                        <Button
                            variant='outlined'
                            onClick={() => setOpenLocationModal(false)}
                            sx={{width: '47%', height: '40px'}}
                        >
Cancel
                            {' '}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{width: '47%', height: '40px'}}
                        >
Save location
                        </Button>
                    </Box>

                </Box>

            </ModalComponent>

            {openDeleteLocationModal
                && <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                '&.MuiBox-root': {maxWidth: 'fit-content'},
                            }}
                                 >
                            <Typography sx={{fontSize: '18px'}}>
Are you sure you want delete
                                "
                                {location.label}
" saved location?
                            </Typography>
                        </Box>,
                        title: 'Delete location',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: handleDelete,
                    }}
                   />
            }

            {moveLocationToGroup
                && <EntityGroupSelectorPopup
                    moveToGroupState={moveLocationToGroup}
                    onChange={() => setMoveLocationToGroup(false)}
                    groups={locationGroups}
                    type={'locations'}
                   />
            }

        </Box>
    )
}
