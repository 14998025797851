import React, {useEffect, useState} from 'react'
import ModalComponent from '../../../../shared/ui/ModalComponent'
import {Box, Button, FormHelperText, IconButton, Typography} from '@mui/material'
import CloseIcon from '../../../../shared/assets/icons/Close'
import {isEmpty} from '../../../../shared/libs/Utils'
import TextField from '@mui/material/TextField'
import {Select} from '../../../../shared/ui/Select'
import {useActionItemsStore} from '../../../../app/store/ActionItemsStore'
import {IconMenuItem} from '../../../../widgets/weatherDefinition/NewWeatherDefinitionPage'

const EditActionItemsCategory = ({defaultValue, onClose}) => {
    const {
        categoryIcons,
        fetchCategoryIcons,
        createCategory,
        editCategory,
    } = useActionItemsStore((state) => state)

    const [categoryName, setCategoryName] = useState(defaultValue.name || null)
    const [categoryIcon, setCategoryIcon] = useState(defaultValue.icon || null)

    useEffect(() => {
        fetchCategoryIcons()
    }, [])

    const handleSaveCategory = () => {
        if (defaultValue.id) {
            const updatedCategory = {
                ...defaultValue,
                name: categoryName,
                icon: categoryIcon.id,
            }
            editCategory(updatedCategory)
        } else {
            const newCategory = {
                name: categoryName,
                icon: categoryIcon.id,
            }
            createCategory(newCategory)
        }
        onClose()
    }

    return (
        <ModalComponent visible={true}>
            <div style={{width: '470px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        lineHeight: '32px',
                    }}
                >
                    <h3>
                        {isEmpty(defaultValue) ? 'New' : 'Edit'}
                        {' '}
                        category
                    </h3>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        sx={{height: '32px', padding: '8px'}}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{width: '16px', height: '16px'}}/>
                    </IconButton>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '4px',
                            alignSelf: 'stretch',
                            flexDirection: 'column',
                        }}
                    >
                        <FormHelperText style={{fontSize: '14px', fontWeight: '400', margin: '0'}}>
                            Name of action item category
                        </FormHelperText>
                        <TextField
                            style={{gap: '8px', width: '100%'}}
                            type='text'
                            placeholder='Enter name'
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                        >
                        </TextField>
                    </div>
                    <Typography variant={'label'}>
                        Icon
                        <Select
                            defaultValue={categoryIcon?.icon}
                            value={categoryIcon?.icon}
                            placeholder={''}
                            style={{width: '100%'}}
                            renderValue={(icon) => (
                                <Box style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                    <Box
                                        className={'row iconBackground'}
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            justifyContent: 'center',
                                            border: '1px solid var(--palette-grey-100)',
                                            borderRadius: '4px',
                                            backgroundColor: 'var(--palette-grey-background)',
                                        }}
                                    >
                                        <svg
                                            className={'customSvg'}
                                            style={{fill: 'var(--palette-primary-dark)'}}
                                        >
                                            <use
                                                height="16"
                                                width="16"
                                                href={categoryIcon?.icon + '#svg2'}
                                            />
                                        </svg>
                                    </Box>
                                    <Box
                                        style={{
                                            color: categoryIcon?.name ? 'var(--palette-primary-dark)' : 'var(--palette-grey-300)',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        {categoryIcon?.name || 'Select icon'}
                                    </Box>
                                </Box>
                            )}
                            MenuProps={{
                                sx: {
                                    '& ul': {
                                        padding: 0,
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(14, auto)',
                                    },
                                },
                            }}
                        >
                            {categoryIcons.map((iconItem) => (
                                <IconMenuItem
                                    key={`icon_${iconItem.id}`}
                                    value={iconItem.id}
                                    onClick={() => setCategoryIcon({...iconItem})}
                                >
                                    <svg
                                        className={'customSvg'}
                                        style={categoryIcon?.id === iconItem.id ? {fill: 'var(--palette-info-dark)'} : {}}
                                    >
                                        <use
                                            height="16"
                                            width="16"
                                            href={`${iconItem.icon}#svg2`}
                                        />
                                    </svg>
                                </IconMenuItem>
                            ))}
                        </Select>
                    </Typography>
                </div>
                <div
                    style={{display: 'flex', gap: '12px', width: '100%'}}
                >
                    <Button
                        style={{fontWeight: '400', width: '50%'}}
                        className='spacer'
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{fontWeight: '400', width: '50%'}}
                        disable={!categoryIcon || !categoryName}
                        className='spacer'
                        variant={'contained'}
                        data-cy={'agree-modal-agree-button'}
                        color={'primary'}
                        onClick={handleSaveCategory}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default EditActionItemsCategory
