import React, {useState} from 'react';
import { Box, IconButton, Typography, Avatar, FormControlLabel, FormGroup } from '@mui/material';
import { CheckBox } from "shared/ui/CheckBox";
import ModalComponent from 'shared/ui/ModalComponent';
import PenIcon from 'shared/assets/icons/Pen';
import UserDetails from 'entities/user/UserDetails';
import EditContactPopup from 'features/contact/EditContactPopup';
import {formatPhoneNumber} from "shared/libs/Utils";


function RecipientLine({ contact, emailChecked, smsChecked, onCheckedChange, editable=false, isUser=false }) {
    const [openModalUserPopUp, setOpenModalUserPopUp] = useState(false);
    const [openModalEditContactPopup, setEditModalContactPopup] = useState(false);
    contact.userName = contact.name

    return (
        <Box className={'contact-line'}>
            <Box
                className={'row gap4'}
                sx={{cursor: 'pointer', width: '200px', flexGrow: 1}}
                onClick={() => setOpenModalUserPopUp(true)}
            >
                <Typography className={'textEllipsis medium'}>
                    {isUser ? contact.username : contact.name}
                </Typography>
            </Box>

            <Box className={'row gap8'} sx={{width: '120px', flexGrow: 1, marginLeft: `${contact.group ? "null" : "18px"}`}}>
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <CheckBox
                                checked={emailChecked}
                                onChange={() => onCheckedChange(!emailChecked, 'email')}
                                disabled={!contact.email}
                            />
                        }
                        label={
                            <Typography>{contact.email ? contact.email : <>&mdash;</>}</Typography>
                        }
                    />
                </FormGroup>
            </Box>

            <Box className={'row gap8'} sx={{width: '180px'}}>
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <CheckBox
                                checked={smsChecked}
                                onChange={() => onCheckedChange(!smsChecked, 'phone')}
                                disabled={!contact.phone}
                            />
                        }
                        label={
                            <Typography>{contact.phone ? formatPhoneNumber(contact.phone) : <>&mdash;</>}</Typography>
                        }
                    />
                </FormGroup>
            </Box>
            {editable &&
                <IconButton
                    variant={'outlined'}
                    size={'tiny'}
                    style={{marginLeft: `${contact.group ? "null" : "17px"}`}}
                    onClick={() => setEditModalContactPopup(true)}
                >
                    <PenIcon size={'small'}/>
                </IconButton>}

            {!editable &&
                <IconButton
                    size={'tiny'}
                    style={{marginLeft: `${contact.group ? "null" : "17px"}`}}
                    disabled
                ></IconButton>
            }

            <ModalComponent visible={openModalUserPopUp}>
                <UserDetails user={contact} onClose={() => setOpenModalUserPopUp(false)} />
            </ModalComponent>

            <ModalComponent visible={openModalEditContactPopup} style={{padding:"0px"}}>
                <EditContactPopup
                    contactData={contact} 
                    onClose={() => setEditModalContactPopup(false)}
                />
            </ModalComponent >

        </Box>
    );
}

export default RecipientLine;
