import {Box} from "@mui/material";
import React from "react";
import {availableToRenderDefinition} from "./availableToRenderDefinition";
import theme from "../../../app/Theme";
import {Operand} from "./Operand";

export function DefinitionRenderLarge({definition}) {
    if (!availableToRenderDefinition(definition)) {
        return <Box></Box>
    }

    return (
        <Box className={'column gap8'} style={{width: '100%'}}>
            {definition.groups.map((group, index) =>
                <RenderGroupLarge key={index} group={{
                    ...group,
                    isFirst: !index,
                }}/>
            )}
        </Box>
    )
}

function RenderGroupLarge({group}) {
    return (
        <div id={'groupCompact'} className={'row gap8'} style={{flexWrap: 'wrap'}}>
            {group.lines.map((line, index) =>
                <RenderLineLarge
                    key={index}
                    line={{
                        ...line,
                        isFirst: !index,
                        isLast: index === group.lines.length - 1,
                    }}
                    group={group}
                />
            )}
        </div>
    )
}

function RenderLineLarge({line, group}) {
    return (
        <Box id={'lineCompact'} className={'row gap8'}>
            {!group.isFirst && line.isFirst &&
                <label className={'medium'}>
                    {group.union}
                </label>
            }
            {!line.isFirst &&
                <label className={'medium'} style={{marginRight: 'auto'}}>
                    {line.union}
                </label>
            }
            {line.isFirst &&
                <div style={{fontSize: '24px', fontWeight: 300, color: 'var(--palette-grey-500)'}}>
                    (
                </div>
            }
            <Operand line={line} group={group}/>
            {line.isLast &&
                <div style={{
                    fontSize: '24px',
                    fontWeight: 300,
                    color: 'var(--palette-grey-500)'
                }}>
                    )
                </div>
            }
        </Box>
    )
}