import React, {useState, useEffect, useRef} from 'react'
import {ManageLocationScreen} from 'widgets/location/ui/manageLocationScreen/ManageLocationScreen'
import {Box, IconButton, Link, Breadcrumbs} from '@mui/material'
import NotificationsStepScreen
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/NotificationsStepScreen'
import WeatherDefinitionStepScreen
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/WeatherDefinitionStepScreen'
import {WeatherDefinitionDetails} from 'entities/weatherDefinition/ui/WeatherDefinitionDetails'
import NewWeatherDefinitionPage from 'widgets/weatherDefinition/NewWeatherDefinitionPage'
import {
    postConfigurationGroup,
    putConfigurationGroup,
    fetchConfigurationGroup,
    fetchWeatherDefinitions,
} from 'pages/alerts/api/FetchAlerts'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import {Spacer} from 'shared/ui/Spacer'
import theme from 'app/Theme'
import 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/StepperScreens.css'
import AgreeModal from 'shared/ui/AgreeModal'
import ReportingStepScreen
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/ReportingStepScreen'
import {useUserStore} from '../../../../../app/store/UserStore'
import AlertConfirmationModal from '../../../../../shared/ui/AlertConfirmationModal'
import {useLocationsStore} from '../../../../../app/store/LocationsStore'

// TODO move to the file with default values (if this file exists)
export const DEFAULT_MESSAGES = {
    forecastAppears: '{{alert_name}} alert at {{location}} beginning {{start_time}} ({{timezone}}) {{start_date}}\n{{email_template}} {{severity}} Forecast Alert: {{alert_name}} expected\nLocation: {{location}}\nDates and Times:\n{{date_value_verbose}} {{ellipsis}}\nAlert Issued: {{issue_start_date}}, at {{issue_start_time}} {{timezone}}',
    forecastUpdates: '{{alert_name}} forecast at {{location}} on {{start_time}} {{start_date}} was just updated',
    weatherEventStarted: '{{severity}} live alert: {{alert_name}} at {{location}} has just started',
    customTime: '{{severity}} forecast alert: {{alert_name}} at {{location}} on {{start_time}} {{start_date}}',
}

export default function StepperScreens({
    currentStep,
    onChange,
    configGroup,
    onClose,
    showSidebar,
    setShowSidebar,
    isPressedSaveButton,
}) {
    const {
        fetchLocations,
    } = useLocationsStore((state) => state)

    const {fetchUser} = useUserStore((state) => state)
    const [selectedLocations, setSelectedLocations] = useState(new Map())
    const [definitions, setDefinitions] = useState([])
    const [openDefinition, setOpenDefinition] = useState(null)
    const [selectedDefinitions, setSelectedDefinitions] = useState(definitions.filter((wd) => wd.id === configGroup.wd) || [])
    const [openNewDefinition, setOpenNewDefinition] = useState(null)
    const [editDefinition, setEditDefinition] = useState(null)
    const [disableSaveConfigs, setDisableSaveConfigs] = useState(false)
    const [isIncludeReport, setIsIncludeReport] = useState(false)
    const [defaultNotificationMessages, setDefaultNotificationMessages] = useState(false)
    const mapPositionRef = useRef({position: [-97, 38], zoom: 4})
    const [lastCreatedLocs, setLastCreatedLocs] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

    const [notificationData, setNotificationData] = useState({
        allow_self_notification_email: true,
        allow_self_notification_sms: true,
        contacts: {},
        report: false,
    })

    const [isCreatingLocationModeNow, setIsCreatingLocationModeNow] = useState(true)

    useEffect(() => {
        setOpenDefinition(null) // listener of step changes
    }, [currentStep])

    useEffect(() => {
        if (configGroup && configGroup.id) {
            fetchConfigurationGroup(configGroup.id).then((data) => {
                if (data && data.notification) {
                    setNotificationData(data.notification)
                    setIsIncludeReport(data.notification.report)
                    setNotificationData({...data.notification, report: data.notification.report})
                }
            })
        }

        fetchUser().then((user)=>{
            setDefaultNotificationMessages(user.default_notification_messages)
        })
            .catch((error) => {
                console.error('Error receiving user data:', error)
            })
    }, [configGroup])

    const handleBackButtonClick = () => {
        setOpenModal(true)
    }
    const handleModalClose = (confirmed) => {
        setOpenModal(false)
        if (confirmed) {
            window.location.href = `${configGroup.wd ? '/settings' : '/alerts'}` // Сhecking where the alert was created from (from "Add alert" button in wd or alerts)
        }
    }

    useEffect(() => {
        if (!configGroup || !configGroup.locations) {
            setSelectedLocations(new Map())
        } else {
            fetchLocations().then((data) => {
                const selectedLocationsId = Object.keys(configGroup.locations).filter((key) => configGroup.locations[key] === true)
                const locs = new Map()
                selectedLocationsId.forEach((id) => {
                    const loc = data.find((e) => e.id === ~~id)
                    if (loc) locs.set(loc.id, loc.coordinates)
                })
                setSelectedLocations(locs)
            })
        }
        if (configGroup) {
            fetchWeatherDefinitions().then((data) => {
                const definitions = data.sort((a, b) => a.name.localeCompare(b.name))
                setDefinitions(definitions)
                setSelectedDefinitions(definitions.filter((wd) => wd.id === configGroup.definition || wd.id === configGroup.wd))
            })
        }
    }, [configGroup])

    const agreeFunc = () => {
        setIsSuccessModalOpen(false)
        window.location.href = '/alerts'
    }

    const handleGoToSettings = (agreed) => {
        if (agreed) {
            if (configGroup.back) {
                onClose(true)
                return
            }
            window.location.href = '/settings#alerts'
        }
        setIsSuccessModalOpen(false)
    }

    const writeIdsToSessionStorage = (alertConfigs) => {
        if (configGroup && configGroup.id) return

        const existingIdsFromStorage = sessionStorage.getItem('newestAlerts')
        const existingIds = existingIdsFromStorage ? JSON.parse(existingIdsFromStorage) : []

        const newSuccessIds = alertConfigs
            .filter((config) => config.success === true)
            .map((config) => config.group_id)

        const updatedIds = Array.from(new Set([...existingIds, ...newSuccessIds]))
        sessionStorage.setItem('newestAlerts', JSON.stringify(updatedIds))
    }

    const onSaveDone = () => {
        setDisableSaveConfigs(false)
        setIsSuccessModalOpen(true)
    }

    const onSave = () => {
        if (isPressedSaveButton) {
            const locs = Array.from(selectedLocations.keys())
            const definitions = selectedDefinitions.map((definition) => definition.id)

            const requests = definitions.map((definitionId) => {
                const dataToSend = {
                    locations: locs,
                    definition: definitionId,
                    notification: notificationData,
                }

                // Set Forecast appears checkbox active for the new alert
                if (notificationData.fc_appears_checked === undefined) {
                    dataToSend.notification.fc_appears_checked = true
                    dataToSend.notification.fc_appears = defaultNotificationMessages.fc_appears
                }

                if (!configGroup.id) {
                    return postConfigurationGroup(dataToSend)
                } else {
                    return putConfigurationGroup(configGroup.id, dataToSend)
                }
            })

            Promise.all(requests)
                .then((data) => {
                    writeIdsToSessionStorage(data)
                    onSaveDone()
                    setDisableSaveConfigs(true)
                })
                .catch((error) => {
                    console.error('Error while saving configurations:', error)
                })
        }
    }

    useEffect(() => {
        onSave() // listener of press button in NewAlertStepper
    }, [isPressedSaveButton])

    const checkIsAlertConfigurationValid = () => {
        const isAnyLocationSelected = selectedLocations.size !== 0

        onChange({
            isAnyDefinitionSelected: selectedDefinitions.length > 0,
            isAnyLocationSelected: isAnyLocationSelected,
            isDisableCreationLocationMode: isCreatingLocationModeNow,
            isValid: selectedDefinitions.length > 0 && isAnyLocationSelected && isCreatingLocationModeNow,
        })
    }

    useEffect(checkIsAlertConfigurationValid, [selectedDefinitions, selectedLocations, isCreatingLocationModeNow, disableSaveConfigs, onChange])

    if (!selectedLocations) return (<></>)

    const onDefinitionChange = (action, value) => {
        if (action === 'select') {
            if (configGroup.id) {
                setSelectedDefinitions(selectedDefinitions.length >= 1 ? [value] : [...selectedDefinitions, value])
            } else {
                if (selectedDefinitions.some((item) => item.id === value.id)) {
                    const updatedDefinitions = selectedDefinitions.filter((item) => item.id !== value.id)
                    setSelectedDefinitions(updatedDefinitions)
                } else {
                    setSelectedDefinitions([...selectedDefinitions, value])
                }
            }
        }
        if (action === 'open') {
            setOpenDefinition(value)
        }
        if (action === 'open_new') {
            setOpenNewDefinition(value)
            setShowSidebar(false)
        }
        if (action === 'edit') {
            setEditDefinition(value)
            setShowSidebar(false)
        }
    }

    const handleCreatingLocationChange = (isCreatingLocation) => {
        setIsCreatingLocationModeNow(isCreatingLocation)
    }

    const locationCreated = (locations) => {
        setLastCreatedLocs(locations)
    }

    return (
        <Box
            className={'NWAPanel'}
            sx={{overflow: 'hidden', flex: '1 1 auto', background: 'white', padding: showSidebar ? '24px' : '0'}}
        >
            {!openDefinition && !openNewDefinition && !editDefinition
                && <Box
                    className={'column gap16 fullWidth'}
                    sx={{
                        alignItems: 'stretch',
                        height: showSidebar ? '100%' : '105%',
                        marginTop: showSidebar ? '0' : '-19px',
                    }}
                >
                    {showSidebar && (<Box
                        className={'toolbar'}
                        style={{padding: '2px 0 0 2px'}}
                    >
                        {!configGroup.back
                            && <>
                                <IconButton
                                    variant={'outlined'}
                                    onClick={handleBackButtonClick}
                                >
                                    <ArrowLeftSmallIcon size={'small'}/>
                                </IconButton>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="none"
                                        href={'/alerts'}
                                    >
                                        <h3>
                                            {configGroup.id ? 'Edit alert configuration' : 'New alert configuration'}
                                        </h3>
                                    </Link>
                                    <h3 style={{color: 'var(--palette-primary-dark)'}}>
                                        {currentStep}
                                    </h3>
                                </Breadcrumbs>
                            </>
                        }
                        {configGroup.back && <>
                            <IconButton
                                onClick={handleBackButtonClick}
                                variant={'outlined'}
                            >
                                <ArrowLeftSmallIcon size={'small'}/>
                            </IconButton>
                            <Breadcrumbs aria-label="breadcrumb">
                                <h3
                                    style={{cursor: 'pointer'}}
                                    onClick={onClose}
                                >
                                    {configGroup.id ? 'Edit alert configuration' : 'New alert configuration'}
                                </h3>
                                <h3 style={{color: 'var(--palette-primary-dark)'}}>
                                    {currentStep}
                                </h3>
                            </Breadcrumbs>
                        </>
                        }
                        <Spacer/>
                    </Box>)}
                    {
                        (currentStep === 'Locations') && (
                            <ManageLocationScreen
                                onChange={setSelectedLocations}
                                showLocations={true}
                                selectLocations={true}
                                selectedLocationsResult={selectedLocations}
                                mapPositionRef={mapPositionRef}
                                onCreatingLocation={handleCreatingLocationChange}
                                stepOfAlert={true}
                                lastCreatedLocs={lastCreatedLocs}
                                onLocationCreated={locationCreated}
                            />
                        )
                    }
                    {
                        (currentStep === 'Weather definition') && (
                            <WeatherDefinitionStepScreen
                                onChange={onDefinitionChange}
                                selectedDefinitions={selectedDefinitions}
                                isEditingMode={!!configGroup.id}
                            />
                        )
                    }
                    {
                        (currentStep === 'Reporting') && (
                            <ReportingStepScreen
                                isIncludeReport={isIncludeReport}
                                includeReportOnChange={() => {
                                    setNotificationData({...notificationData, report: !isIncludeReport})
                                    setIsIncludeReport(!isIncludeReport)
                                }}
                            />
                        )
                    }
                    {
                        (currentStep === 'Notifications') && (
                            <NotificationsStepScreen
                                notificationData={notificationData}
                                onNotificationDataChange={setNotificationData}
                                configGroupId={configGroup.id}
                                showSidebar={showSidebar}
                                setShowSidebar={setShowSidebar}
                                defaultMessages={defaultNotificationMessages}
                                setDefaultNotificationMessages={setDefaultNotificationMessages}
                            />
                        )
                    }
                </Box>}

            {openDefinition
                && <WeatherDefinitionDetails
                    definition_id={openDefinition.id}
                    editable={true}
                    onChange={() => {
                        setOpenDefinition(null)
                    }}
                    disableAddAlertButton={true}
                    onDeleteFromAlert={() => {
                        setSelectedDefinitions([])
                    }}
                    setShowSidebar={setShowSidebar}
                />
            }

            {openNewDefinition
                && <NewWeatherDefinitionPage
                    sx={{width: '100%'}}
                    onClose={() => {
                        setOpenNewDefinition(false)
                        setShowSidebar(true)
                    }}
                    onSave={(definition) => {
                        setSelectedDefinitions(configGroup.id ? [definition] : [...selectedDefinitions, definition])
                    }}
                />
            }

            {editDefinition
                && <NewWeatherDefinitionPage
                    sx={{width: '100%'}}
                    defaultValue={editDefinition}
                    onClose={() => {
                        setEditDefinition(false)
                        setShowSidebar(true)
                    }}
                />
            }
            {openModal
                && <AgreeModal
                    data={{
                        agreeFunc: handleModalClose,
                        title: 'Discard weather alert',
                        message: 'Are you sure you want discard your changes?',
                        agreeMsg: 'Discard',
                        disAgreeMsg: 'Go back',
                    }}
                />
            }
            {isSuccessModalOpen && (
                <AlertConfirmationModal
                    agreeFunc={agreeFunc}
                    goToSettingsFunc={handleGoToSettings}
                    selectedDefinitions={selectedDefinitions}
                    selectedLocations={selectedLocations}
                />
            )}
        </Box>
    )
}

