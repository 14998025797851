import {
    Dialog,
    IconButton,
    Box,
    Button,
    Divider,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material'
import CloseIcon from 'shared/assets/icons/Close'
import React, {useEffect, useState} from 'react'
import {ReactComponent as AlertRectangle} from '../assets/svg/confirmationPopUp/AlertRectangle.svg'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {
    ReactComponent as ConfirmationRectangle2,
} from 'shared/assets/svg/confirmationPopUp/ConfirmationAlertRectangle.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as ConfirmationAlertPin} from 'shared/assets/svg/confirmationPopUp/ConfirmationAlertPin.svg'
import {ReactComponent as ConfirmationIcon} from 'shared/assets/svg/confirmationPopUp/ConfirmationItemLow.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import theme from '../../app/Theme'
import MapMarkerIcon from '../assets/icons/MapMarker'
import MapPinIcon from '../assets/icons/MapPin'
import {useLocationsStore} from '../../app/store/LocationsStore'

export default function AlertConfirmationModal({agreeFunc, goToSettingsFunc, selectedDefinitions, selectedLocations}) {
    const {
        fetchLocations,
    } = useLocationsStore((state) => state)

    const [isSingleWeatherDefinition] = useState(selectedDefinitions.length <= 1)
    const [selectedLocationsInAlert, setSelectedLocationsInAlert] = useState()

    useEffect(() => {
        fetchLocations().then((locs) => {
            const selectedLocs = locs.filter((item) => selectedLocations.has(item.id))
            setSelectedLocationsInAlert(selectedLocs)
        })
    }, [])

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '504px',
                gap: '24px',
                padding: '12px 16px',
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    Well done, your alert is created!
                </Box>
                <IconButton
                    variant={'outlined'}
                    onClick={agreeFunc}
                    size={'small'}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </Box>
            <Box style={{position: 'relative', height: '80px', overflow: 'hidden'}}>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: 'absolute', left: '-25px', top: '35px'}}>
                    <ConfirmationRectangle2/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '120px', top: '52px'}}>
                    <ConfirmationIcon/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '0px', overflow: 'hidden'}}>
                    <AlertRectangle style={{borderRadius: '0px 12px 12px 0px'}}/>
                </Box>
                <Box sx={{position: 'absolute', right: '92px', top: '0px'}}>
                    <ConfirmationAlertPin/>
                </Box>
            </Box>
            <Box style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                {isSingleWeatherDefinition &&
                    <Box
                        style={{
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{fontSize: '16px', fontWeight: '500', color: 'var(--palette-grey-900)'}}>
                            {selectedDefinitions[0].name}
                        </div>
                        <Chip
                            variant={theme.palette.severity.variant[selectedDefinitions[0].severity]}
                            size={'large'}
                            label={selectedDefinitions[0].severity}
                        />
                    </Box>
                }
                <Box className={'fullWidth'}>
                    <Accordion
                        className={'fullWidth'}
                        variant={'light'}
                    >
                        <AccordionSummary
                            variant={'light'}
                        >
                            <Box className={'row gap4'}>
                                <div style={{fontSize: '14px', fontWeight: '500', color: 'var(--palette-grey-900)', display: 'flex', gap: '4px', alignItems: 'center'}}>
                                    <MapMarkerIcon size={'small'}/>
                                    Locations (
                                    {selectedLocationsInAlert?.length}
)
                                </div>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails
                            className={'gap8 locationGroup'}
                            variant={'light'}
                        >
                            <Box
                                style={{
                                    display: 'flex', gap: '8px', flexWrap: 'wrap', maxHeight: '90px', overflow: 'auto',
                                }}
                            >
                                {selectedLocationsInAlert?.map((loc) => (
                                    <Chip
                                        icon={<MapPinIcon size={'small'}/>}
                                        label={loc.label}
                                        size={'small'}
                                        key={loc.id}
                                    />
                                ))}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {!isSingleWeatherDefinition &&
                    <Box>
                        We've created a separate alert configuration for each weather definition. Once a weather event
                        triggers an alert, you can access and manage the individual alerts on the dedicated Alerts
                        page.
                    </Box>
                }
            </Box>
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                {isSingleWeatherDefinition ? 'You can view an alert on the Alerts page once weather conditions are met and the alert is triggered. To manage alert settings, go to Settings > Alerts.'
                    : 'To modify specific alert settings, navigate to Settings > Alerts and choose the desired alert.'}
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <Divider/>
                <Box sx={{display: 'flex', gap: '12px'}}>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        onClick={agreeFunc}
                    >
                        Got it
                    </Button>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={goToSettingsFunc}
                    >
                        Go to Settings
                    </Button>
                </Box>
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
