import {styled} from '@mui/material/styles'
import {PickersDay as MuiPickersDay} from '@mui/x-date-pickers'
import React from 'react'

import theme from 'app/Theme'

const StyledPickersDay = styled(MuiPickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'today'
        && prop !== 'dayIsBetween'
        && prop !== 'isFirstDay'
        && prop !== 'isLastDay',
})(({outsideCurrentMonth, today, dayIsBetween, isFirstDay, isLastDay}) =>
    (
        {
            ...(outsideCurrentMonth && {
                color: 'var(--palette-action-disabled)',
            }),
            ...(today && {
                color: 'var(--palette-primary-dark)',
                borderColor: 'var(--palette-primary-dark)',
                borderWidth: '1px',
                borderStyle: 'solid',
            }),
            ...(dayIsBetween && {
                backgroundColor: 'var(--palette-grey-background)',
                color: 'var(--palette-primary-dark)',
                '&:hover, &:focus': {
                    color: 'white',
                    backgroundColor: 'var(--palette-primary-dark)',
                },
            }),
            ...((isFirstDay || isLastDay) && {
                color: 'white',
                backgroundColor: 'var(--palette-primary-dark)',
                '&:hover, &:focus': {
                    color: 'white',
                    backgroundColor: 'var(--palette-primary-dark)',
                },
            }),
        }))

function PickersDay({day, today, isFirstDay, isLastDay, dayIsBetween, ...other}) {
    return (
        <div
            className={'row'}
            style={{
                width: 40,
                height: 32,

                ...((isFirstDay || isLastDay || dayIsBetween) && {
                    backgroundColor: 'var(--palette-grey-100)',
                }),
                ...(isFirstDay && {
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }),
                ...(isLastDay && {
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                }),
            }}
        >
            <StyledPickersDay
                {...other}
                day={day}
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
                today={today}
            />
        </div>
    )
}

function PickersDayBoundaries({isFirstDay, isLastDay, today, ...other}) {
    return (
        <div
            className={'row'}
            style={{
                width: 40,
                height: 32,
                backgroundColor: 'var(--palette-grey-background)',
                ...(isFirstDay && {
                    width: 36,
                    marginLeft: '4px',
                    justifyContent: 'flex-start',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }),
                ...(isLastDay && {
                    width: 36,
                    marginRight: '4px',
                    justifyContent: 'flex-end',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                }),
            }}
        >
            <StyledPickersDay
                {...other}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
                sx={{
                    ...(isFirstDay && {}),
                    ...(isLastDay && {}),
                }}
            />
        </div>
    )
}

export function RangePickersDay({day, dayRange = [], today, ...other}) {
    if (dayRange.length < 2) {
        return <PickersDay
            day={day}
            today={today}
            {...other}
        />
    }

    const start = dayRange[0]
    const end = dayRange[1]

    const dayIsBetween = day.isBetween(start, end, null, '[]')
    const isFirstDay = day.isSame(start, 'day')
    const isLastDay = day.isSame(end, 'day')

    if (isFirstDay || isLastDay) {
        return <PickersDayBoundaries
            {...other}
            day={day}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
            today={today}
        />
    } else {
        return <PickersDay
            {...other}
            today={today}
            day={day}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
        />
    }
}
