import React, {useEffect, useState} from 'react'
import './Map.css'
import {MapLibre} from 'pages/map/ui/MapLibre'
import {fetchRealAlertByAid, fetchMapProducts} from '../../alerts/api/FetchAlerts'
import {productNamesByApiType} from 'shared/libs/AlertMapping'
import {prepApiAlert} from 'pages/alerts/ui/AlertsPage'
import {EditLocationScreen} from 'widgets/location'
import ADDITIONAL_PRODUCTS from '../model/Tile/AdditionalProducts.js'
import CircularProgress from '@mui/material/CircularProgress'
import {useLocationsStore} from '../../../app/store/LocationsStore'

export function Map() {
    const {
        locations,
        editingLocation,
        fetchLocations,
        toggleEditingLocation,
    } = useLocationsStore((state) => state)

    const [initialState, setInitialState] = useState()
    const [products, setProducts] = useState()

    useEffect(() => {
        fetchLocations()
        fetchMapProducts().then((prods) => {
            ADDITIONAL_PRODUCTS.forEach((p)=>prods.push(p))
            setProducts(prods)
        })
    }, [])

    useEffect(() => {
        if (!products) return
        const urlParams = new URLSearchParams(window.location.search)
        const aid = urlParams.get('alert_id')
        if (!aid) return
        fetchRealAlertByAid(aid).then((alert) => {
            if (!alert) return
            const alert_idx = (aid.indexOf('_') > 0) ? parseInt(aid.split('_')[1]) : 0
            alert = prepApiAlert(alert, true)[alert_idx]
            if (!alert) return
            const productNames = mapProducts(alert.api_alert)
            const locations = alert.location_id ? [alert.location_id] : []
            const dates = {
                startDate: (new Date(alert.start_date)).valueOf(),
                endDate: (new Date(alert.end_date)).valueOf(),
            }
            const initialProds = productNames.map((name) => {
                const p = products.find((p) => (p.name === name))
                return p && p.id
            }).filter((prod) => !!prod)
            setInitialState({
                initialLocations: locations,
                initialDates: dates,
                initialProducts: initialProds,
            })
        })
    }, [products])

    if (!locations || !products) {
        return (
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', flexDirection: 'column'}}>
                <CircularProgress />
                <div>
Loading...
                </div>
            </div>
        )
    }

    return (
        <div className='mapWrapper'>
            {!editingLocation
                && <MapLibre
                    initialState={initialState}
                    locations={locations}
                    products={products}
                />
            }
            {editingLocation
                && <EditLocationScreen
                    sx={{width: '100%'}}
                    closeOnSave={false}
                    onClose={() => toggleEditingLocation(null)}
                />
            }
        </div >
    )
}

function mapProducts(data) {
    if (data && data.alert_type !== 'usereventsalert') {
        return productNamesByApiType[data.alert_type] || []
    } else {
        try {
            const rules = data.weather_definition.rules
            if (!rules) return []
            const productNames = new Set()
            rules.forEach((rule) => {
                if (rule.lines) {
                    rule.lines.forEach((l) => {
                        if (l.product_name !== 'Weekdays' && l.product_name !== 'Months' && l.product_name !== 'Time Range') {
                            productNames.add(l.product_name)
                        }
                    })
                }
            })
            return Array.from(productNames)
        } catch (err) {
            console.log(err)
            return []
        }
    }
}
