import maplibregl from '!maplibre-gl' // ! is important here
import {Box} from '@mui/material'
import 'maplibre-gl/dist/maplibre-gl.css'
import 'pages/map/ui/css/map.css'
import 'pages/map/ui/css/conds.css'
import 'pages/map/ui/css/style.css'
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker'
import {transformer} from 'pages/map/model/utils/urltransformer'
import {Legends} from 'pages/map/ui/legends/legends'
import MapMenu from 'pages/map/ui/MapMenu'
import {MapSidebar} from 'pages/map/ui/MapSidebar'
import {TimeLineMap} from 'pages/map/ui/timeline/timeline'
import React, {useEffect, useRef, useState} from 'react'
import requester from 'shared/libs/requester/baron-signature.js'
import {Signature} from 'shared/libs/requester3/baron-signature'
import {ProductsController, TEXT_PRODUCTS_CODES} from '../model/Tile/ProductsController'
import {
    DistanceTool,
    PinPointTool,
    PointQueryTool,
    SwitchModeTool,
} from 'features/mapTools'
import {useViewsStore} from '../../../app/store/ViewsStore'

const signature = new Signature()

maplibregl.workerClass = maplibreglWorker

const NON_STANDARD_PROJECTION = {
    'north-american-radar': 'Mask1-Mercator',
}

export const MapLibre = ({initialState = undefined, locations, products}) => {
    const currentDateInMs = new Date().getTime()

    const [stateDate, setStateDate] = useState({
        startDate: currentDateInMs - 3600000,
        endDate: currentDateInMs,
    })

    const {
        fetchDefaultMapView,
        createDefaultMapView,
    } = useViewsStore((state) => state)

    const productsController = useRef()
    const [locationsLayers, setLocationLayers] = useState({})
    const [activeProducts, setActiveProducts] = useState([])
    const [activeLocations, setActiveLocations] = useState([])
    const [locationHash, setLocationHash] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selectedTime, setSelectedTime] = useState(currentDateInMs)
    const mapLibreElem = useRef()
    const [isPlaying, setIsPlaying] = useState(false)
    const [clearPQ, setClearPQ] = useState(false)
    const [activeTool, setActiveTool] = useState(null)
    const [mapLoaded, setMapLoaded] = useState(false)

    useEffect(() => {
        productsController.current &&
        productsController.current.setTimeRange(stateDate)
    }, [stateDate])

    const initProductsAndLocationsFromAppState = async () => {
        const urlParams = new URLSearchParams(window.location.search)
        const alertId = urlParams.get('alert_id')

        if (!alertId) {
            fetchDefaultMapView(products, locations, setActiveProducts, setActiveLocations, (newHash) => {
                setLocationHash(newHash)
                window.location.hash = newHash
            })
        }
    }

    useEffect(() => {
        if (!initialState || !mapLoaded) return
        // reset
        setActiveLocations(initialState.initialLocations || [])
        initialState.initialDates && setStateDate(initialState.initialDates)
        initialState.initialDates && setSelectedTime(initialState.initialDates.startDate)
        setActiveProducts(initialState.initialProducts || [])
    }, [initialState, mapLoaded])

    useEffect(() => {
        requester.isready(componentDidMount)
    }, [])

    useEffect(() => {
        activeLocations.forEach((id) => {
            if (locationsLayers[id]) return
            const loc = locations.find((p) => (p.id === id))
            if (!loc) {
                return
            }
            locationsLayers[id] = new maplibregl.Marker({color: 'var(--palette-primaryRed-light)', scale: 0.5})
                .setLngLat(loc.coordinates)
                .addTo(window.map)
            locationsLayers[id].getElement().setAttribute('title', loc.label)
        })

        const _clearedLocations = Object.assign({}, locationsLayers)
        for (const id in locationsLayers) {
            if (!activeLocations.includes(+id)) {
                _clearedLocations[id] && _clearedLocations[id].remove()
                delete _clearedLocations[id]
            }
        }

        setLocationLayers(_clearedLocations)
    }, [activeLocations])

    useEffect(() => {
        const mapProducts = []
        const productObjects = [] // REALLY CRINGE!!! TODO: pass into entire product object, not only product code
        activeProducts.forEach((p) => {
            const prod = products.find((pr) => (pr.id === p))
            if (prod) {
                const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                    ? prod.api_product_code
                    : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`
                mapProducts.push(productCode)
                productObjects.push(prod)
            } else {
                console.error(`Product with id ${p} not found.`)
            }
        })

        if (productsController.current) {
            productsController.current.setProducts(mapProducts, productObjects)
            reEnableWmsIfNeed()
        }
    }, [activeProducts])

    const reEnableWmsIfNeed = () => {
        const isNeedToReEnableWMs = productsController.current.mode
        const isNeedRePlay = isPlaying
        productsController.current.switchWMSMode(false)

        setIsPlaying(false)
        setIsLoading(false)

        if (isNeedToReEnableWMs) {
            setIsLoading(true)
            productsController.current.switchWMSMode(true, () => {
                setIsLoading(false)
                isNeedRePlay && setIsPlaying(true)
            })
        }
    }

    function componentDidMount() {
        const map = new maplibregl.Map({
            container: 'map',
            style: 'https://cdnmaps.velocityweather.com/styles/planet-full/style.json',
            hash: true,
            transformRequest: transformer(requester),
            minZoom: 2,
            dragRotate: false,
            touchZoomRotate: false,
        })
        window.map = map

        map.on('disableProducts', handleDisableProducts)
        map.on('load', () => {
            mapLibreElem.current = map
            initProductsAndLocationsFromAppState()
            setMapLoaded(true)
        })
        map.on('moveend', () => {
            const newHash = window.location.hash
            setLocationHash(newHash)
        })
        map.addControl(new maplibregl.NavigationControl({
            showCompass: false,
        }), 'bottom-left')
        map.addControl(new maplibregl.ScaleControl(), 'bottom-right')
        map.addControl(new maplibregl.ScaleControl({
            unit: 'imperial',
        }), 'bottom-right')
        map.addControl(new maplibregl.AttributionControl({
            customAttribution: ['Lightning data provided by ENTLN Lightning',
                '<a href="https://baronweather.com/" target="_blank">Baron Weather</a>'],
        }))
        map.addControl(new maplibregl.FullscreenControl())
        map.on('movestart', () => {
            setIsPlaying(false)
            productsController.current && productsController.current.switchWMSMode(false)
            setIsPlaying(false)
        })

        productsController.current = new ProductsController({
            signature,
            map,
            startDate: stateDate.startDate,
            endDate: stateDate.endDate,
            time: currentDateInMs,
            onChangeTimeRange: setStateDate,
        })
    }

    const handleDisableProducts = ({productsToBeDisabled}) => {
        setActiveProducts((prevState) => prevState.filter(
            (product) => !productsToBeDisabled.includes(product),
        ))
    }

    function clearPQPopups() {
        setClearPQ(!clearPQ)
    }

    useEffect(() => {
        if (!mapLibreElem.current) {
            // map is not initialized yet
            return
        }
        // control where we get to the map
        const urlParams = new URLSearchParams(window.location.search)
        const alertId = urlParams.get('alert_id')
        if (!alertId) {
            createDefaultMapView({
                name: 'defaultmapview',
                order: 1,
                data_json: {
                    activeProducts: activeProducts,
                    activeLocations: activeLocations,
                    locationHash: locationHash,
                },
            })
        }
    }, [activeProducts, activeLocations, locationHash])

    const _handleProductSwitch = (prods) => {
        setActiveProducts(prods)
    }

    return (
        <Box className={'row gap0 fullHeight fullWidth'}>
            <MapSidebar
                setActiveProducts={setActiveProducts}
                onSwitchProduct={_handleProductSwitch}
                reEnableWmsIfNeed={reEnableWmsIfNeed}
                onSwitchLocation={setActiveLocations}
                activeProducts={activeProducts}
                activeLocations={activeLocations}
                products={products ? products.filter((p) => (!!p.api_product_code)) : []}
                locations={locations}
            />
            <Box
                className="MapLibre spacer"
                style={{
                    height: '100%', display: 'flex',
                    flexDirection: 'column', alignItems: 'stretch',
                    borderRadius: '16px 0 0 16px', overflow: 'hidden',
                    boxShadow: '-4px 0px 24px 0px #677A8E3D',
                }}
            >
                <MapMenu
                    onSwitchProduct={setActiveProducts}
                    onSwitchLocation={setActiveLocations}
                    onSwitchLocationHash={(newMapPosition) => {
                        setLocationHash(newMapPosition)
                        window.location.hash = newMapPosition
                    }}
                    activeProducts={activeProducts}
                    activeLocations={activeLocations}
                    onClearLocations={() => setActiveLocations([])}
                    onClearLayers={() => setActiveProducts([])}
                    onAllClear={() => {
                        setActiveProducts([])
                        setActiveLocations([])
                        clearPQPopups()
                    }}
                    onClearPqPopups={() => clearPQPopups()}
                    locationHash={locationHash}
                />
                <div
                    className="baronmap"
                    id="baronmapwrapper"
                >
                    <div id="map"></div>

                    {activeProducts.length > 0 && (
                        <>
                            <div
                                style={{
                                    width: '100%', height: '120px',
                                    position: 'absolute', bottom: '0px',
                                    zIndex: '3', display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(240, 242, 245, 0.1)',
                                    boxShadow: 'rgba(0, 0, 0, 0.12) 0px -2px 12px 0px',
                                    backdropFilter: 'blur(2px)',
                                }}
                            >
                                <TimeLineMap
                                    // need to add inital value or keep time on map element
                                    isLoading={isLoading}
                                    startDate={stateDate.startDate}
                                    endDate={stateDate.endDate}
                                    onChangeTime={(selectedTime) => {
                                        productsController.current && productsController.current.setTime(selectedTime)
                                        window.map.fire('selectedTime')
                                    }}
                                    onPlayBtn={(isPause) => {
                                        if (!isPause) {
                                            setIsLoading(true)
                                            productsController.current.switchWMSMode(true, () => {
                                                setIsLoading(false)
                                            })
                                        }
                                    }
                                    }
                                    onChangePlaying={setIsPlaying}
                                    isPlaying={isPlaying}
                                    selectedTime={selectedTime}
                                ></TimeLineMap>
                            </div>
                        </>
                    )}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '226px',
                            width: 'fit-content',
                            height: 'fit-content',
                            left: '23px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        { productsController.current?.textProductController?.isEnabled('metar') &&
                            <SwitchModeTool
                                product={productsController.current.textProductController.getProduct('metar')}
                            />
                        }
                        <DistanceTool
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('DistanceTool')}
                        />
                        <PinPointTool
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('PinPointTool')}
                        />
                        <PointQueryTool
                            productsController={productsController}
                            products={products}
                            activeProducts={activeProducts}
                            clear={clearPQ}
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('PointQuery')}
                        />
                    </div>
                </div>
                <Legends
                    activeProductsIndexes={activeProducts}
                    products={products}
                    nonStandartProjection={NON_STANDARD_PROJECTION}
                />
            </Box>
        </Box>
    )
}
