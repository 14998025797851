import theme from "../../../app/Theme";
import {productsDict} from "../../../entities/weatherDefinition/api/Products";
import {Chip} from "@mui/material";
import React from "react";

export function Operand({line, group}) {
		const units = line.units || '';
		return (
				<span
						className={'row gap4'}
						style={{
								background: 'white',
								padding: '4px 10px 4px 12px',
								border: '1px solid',
								borderColor: 'var(--palette-grey-100)',
								borderRadius: '12px',
								minHeight: '32px',
								flexWrap: 'wrap',
								minWidth: '380px',
								...(line.isFirst && group.isFirst && {
										flexGrow: 1,
								})
						}}
				>
                {productsDict && productsDict[line.product_name] && productsDict[line.product_name].icon &&
		                <svg
				                className={'customSvg'}
				                style={{maxHeight: '16px', maxWidth: '16px',}}
		                >
				                <use
						                height="16"
						                width="16"
						                href={`${productsDict[line.product_name].icon}#svg2`}
				                />
		                </svg>
                }
						{line.product_name}
						<Chip
								size={'small'}
								label={
										<div className={'paragraph'}>
												{line.values.length === 2
														? `${line.values[0]} to ${line.values[1]} ${units}`
														: `${line.relation} ${line.values[0]} ${units}`
												}
										</div>
								}
								sx={{marginLeft: 'auto'}}
						/>
        </span>
		)
}