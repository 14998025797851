import {
    Box,
    Button,
    IconButton,
    FormHelperText,
} from '@mui/material'
import React, {useState} from 'react'
import ModalComponent from 'shared/ui/ModalComponent'
import CloseIcon from '../../../../shared/assets/icons/Close'
import TextField from '@mui/material/TextField'
import {useContactsStore} from '../../../../app/store/ContactsStore'
import {useLocationsStore} from '../../../../app/store/LocationsStore'

export function EditGroupName({editGroupState, onChange, isContact= false}) {
    const {
        contacts,
        editGroupName,
    } = useContactsStore((state) => state)

    const {
        locations,
        editLocationGroupName,
    } = useLocationsStore((state) => state)

    const [editGroup, setEditGroup] = useState({...editGroupState})

    const saveContactGroupName = async () => {
        if (!editGroup || editGroup.original === editGroup.new || !editGroup.new) {
            onChange()
            return
        }

        const contactsToUpdate = contacts.filter((contact) => contact.group === editGroup.original)
        editGroupName(contactsToUpdate, editGroup.new)
        onChange()
    }

    const saveLocationGroupName = async () => {
        if (!editGroup || editGroup.original === editGroup.new || !editGroup.new) {
            onChange()
            return
        }

        const locationsToUpdate = locations.filter((loc) => loc.location_group === editGroup.original)
        if (locationsToUpdate.length === 0) {
            onChange()
            return
        }

        try {
            await editLocationGroupName(locationsToUpdate.map((loc) => loc.id), {location_group: editGroup.new})
            onChange(editGroup)
        } catch (error) {
            console.error('Error updating locations:', error)
        }
    }

    return (
        <ModalComponent visible={true}>
            <Box sx={{width: '472px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '24px'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch'}}>
                    <h3>
                        {isContact ? 'Edit group name' : 'Edit location group'}
                    </h3>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        sx={{height: '32px', padding: '8px'}}
                        onClick={() => onChange(false)}
                    >
                        <CloseIcon sx={{width: '16px', height: '16px'}}/>
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-start', gap: '4px', alignSelf: 'stretch', flexDirection: 'column'}}>
                    <FormHelperText sx={{fontSize: '14px', fontWeight: '400', margin: '0'}}>
                        {isContact ? 'Group name' : 'Location group name'}
                    </FormHelperText>
                    <TextField
                        sx={{gap: '8px', width: '100%'}}
                        type='text'
                        placeholder={isContact ? 'Group name' : 'Location group name'}
                        value={editGroup.new}
                        onChange={(e) => setEditGroup({original: editGroup.original, new: e.target.value})}
                    >
                    </TextField>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'flex-start', gap: '12px', alignSelf: 'stretch', paddingTop: '12px', borderTop: '1px solid #F0F2F5'}}>
                    <Button
                        className='spacer regular'
                        onClick={isContact ? saveContactGroupName : saveLocationGroupName}
                    >
                            Save
                    </Button>
                    <Button
                        className='spacer regular'
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={() => {
                            onChange(false)
                        }}
                    >
                            Cancel
                    </Button>
                </Box>
            </Box>
        </ModalComponent>
    )
}
