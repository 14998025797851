import {IconButton} from '@mui/material'
import React, {useState} from 'react'

import './SwitchModeTool.scss'
import switchModeIcon from 'shared/assets/svg/switch_button.svg'

export default function SwitchModeTool({product}) {
    const [isActive, setIsActive] = useState(true)

    const handleClickOnButton = () => {
        setIsActive((prevState) => {
            const newIsActive = !prevState
            product.switchMode(newIsActive)

            return newIsActive
        })
    }

    return (
        <IconButton
            data-cy={'switch-mode-tool'}
            onClick={handleClickOnButton}
            variant={'outlined'}
            size={'small'}
            className={`switch-mode-tool ${isActive ? 'active' : ''}`}
        >
            <img
                src={switchModeIcon}
                alt="Switch Mode Icon"
            />
        </IconButton>
    )
}
