import {Box, Button, Link, TextField, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {isEmailValid} from 'shared/libs/Utils';
import {signupUser} from '../../shared/libs/Auth';
import {ErrorMessages} from "shared/ui/ErrorMessages";
import {PhoneField} from '../../shared/ui/PhoneField/PhoneField';
import {Spacer} from 'shared/ui/Spacer';
import cloud from '../../shared/assets/svg/cloud.svg';
import logo from '../../shared/assets/png/logo.png';
import 'pages/Auth.css';
import 'pages/register/RegisterPage.css';
import {isPhoneValid} from "../../shared/libs/inputValidators/AuthValidator";

const SECTIONS = {
    SignupSection: "SignupSection",
    CheckEmailSection: "CheckEmailSection"
}

export default function RegisterPage() {
    const [section, setSection] = useState(SECTIONS.SignupSection)
    const signupSectionRef = useRef(null)
    const checkEmailSectionRef = useRef(null)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState({});
    const [validateError, setValidateError] = useState({
        email: '',
        phone: '',
    })
    const [showErrorEmail, setShowErrorEmail] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const emailValid = isEmailValid(email);
        const phoneValid = isPhoneValid(phone);

        const isValid = !!firstName && !!lastName && emailValid && phoneValid;
        setValidated(isValid);
        setValidateError({
            email: email ? (emailValid ? '' : 'Invalid email address format') : '',
            phone: phone ? (phoneValid ? '' : 'Invalid phone number format, should be (XXX)XXX-XXXX') : '',
        });
    }, [firstName, lastName, email, phone]);

    function handleSignup(event) {
        event?.preventDefault();
        signupUser(firstName, lastName, email, phone, organization)
            .then(() => {
                setSection(SECTIONS.CheckEmailSection)
            })
            .catch(error => {
                setError(true)
                setErrorData(error?.response?.data)
            })
    }


    function SignupSection(props) {
        return (
            <div className={'section'} {...props} >
                <div className={'sectionHeader'}>
                    <h3>Create an Account</h3>
                </div>
                <Box
                    className={'form'}
                    component='form'
                    onSubmit={handleSignup}
                    sx={{height: 'fit-content'}}
                >
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <img className={'baronLogo'} src={logo} alt={'baron_logo'}/>
                    </div>
                    <div className={'row'}>
                        <Typography component={'span'} variant={'label'} sx={{flexGrow: 1}}>
                            First name
                            <TextField
                                data-cy={'first-name'}
                                autoComplete={'on'}
                                onChange={event => setFirstName(event.target.value)}
                            />
                        </Typography>
                        <Typography component={'span'} variant={'label'} sx={{flexGrow: 1}}>
                            Last name
                            <TextField
                                data-cy={'last-name'}
                                autoComplete={'on'}
                                onChange={event => setLastName(event.target.value)}
                            />
                        </Typography>
                    </div>
                    <Typography component={'span'} variant={'label'}>
                        Email
                        <TextField
                            data-cy={'email'}
                            autoComplete={'on'}
                            onChange={(event) => setEmail(event.target.value)}
                            onBlur={() => setShowErrorEmail(true)}
                            placeholder='e.x username@gmail.com'
                        />
                        {showErrorEmail && validateError.email && (
                            <Typography color="error" variant="caption">
                                {validateError.email}
                            </Typography>
                        )}
                    </Typography>
                    <Typography component={'span'} variant={'label'}>
                        Phone
                        <PhoneField
                            data-cy={'phone'}
                            errorBorder={false}
                            onChange={setPhone}
                            onBlur={() => setShowError(true)}
                            phone={phone}
                        />
                        {showError && validateError.phone && (
                            <Typography color="error" variant="caption">
                                {validateError.phone}
                            </Typography>
                        )}
                    </Typography>
                    <Typography component={'span'} variant={'label'}>
                        Organization (optional)
                        <TextField
                            data-cy={'organization'}
                            onChange={event => setOrganization(event.target.value)}
                        />
                    </Typography>
                    <div id={'termsOfService'} style={{paddingLeft: '4px'}}>
                        By creating an account you are agreeing to our
                        <Link href={'/terms-and-conditions'}> Terms of Service</Link> and
                        <Link href={'/privacy-policy'}> Privacy Policy</Link>
                    </div>
                    <ErrorMessages error={error} errorData={errorData}/>

                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <Button
                            data-cy={'signup-button'}
                            type={'submit'}
                            disabled={!validated}
                            onClick={handleSignup}
                            sx={{width: '160px'}}
                        >
                            Sign up
                        </Button>
                    </div>
                </Box>
            </div>
        )
    }

    function CheckEmailSection(props) {
        return (
            <Box className={'section'}
                 {...props}
            >
                <Box
                    className={'form'}
                    component='form'
                    sx={{height: 'fit-content',}}
                >
                    <div className={'row'} style={{justifyContent: 'center'}}>
                        <img className={'baronLogo'} src={logo} alt={'baron_logo'}/>
                    </div>
                    <Spacer/>
                    <Box className={'sectionHeader'} style={{margin:"0"}}>
                        <h3>Check your email</h3>
                        <Box className={'subtitle'}>
                            In order to protect your security, we've sent an email to registered -<br/>
                            <b>{email}</b>. Use that email to start your work with <b>Baron Weather</b>.
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <div className={'background'}>
            <div className={'nightGradient'}>
                <div className={'rightGradient'}>
                    <img className={'night_cloud_0'} src={cloud} alt={'cloud'}/>
                    <img className={'night_cloud_1'} src={cloud} alt={'cloud'}/>
                    <img className={'night_cloud_2'} src={cloud} alt={'cloud'}/>
                    <img className={'night_cloud_3'} src={cloud} alt={'cloud'}/>
                    <div className={'container'}>
                        <div className={'alreadyHaveAccountContainer'}>
                            <div className={'alreadyHaveAccountSection'}>
                                <h3 className={'white'}>
                                    Already have an account?
                                </h3>
                                <Button
                                    href='/login'
                                    variant={'outlined'}
                                    sx={{width: '160px'}}
                                >
                                    Log in
                                </Button>
                            </div>
                        </div>
                        <div style={{flexGrow: 1, display: 'grid'}}>
                            <CSSTransition
                                in={section === SECTIONS.SignupSection}
                                nodeRef={signupSectionRef}
                                timeout={1000}
                                classNames={'section'}
                                unmountOnExit
                            >
                                {SignupSection({ref: signupSectionRef})}
                            </CSSTransition>
                            <CSSTransition
                                in={section === SECTIONS.CheckEmailSection}
                                nodeRef={checkEmailSectionRef}
                                timeout={1000}
                                classNames={'section'}
                                unmountOnExit
                            >
                                {CheckEmailSection({ref: checkEmailSectionRef})}
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
