import {Box, Button, Typography} from '@mui/material'
import {isEmpty} from 'shared/libs/Utils'
import React, {useState, useEffect} from 'react'
import WeatherDefinitionLine from './WeatherDefinitionLine'
import {
    fetchWeatherDefinitions,
    deleteWeatherDefinition,
    fetchAlertConfigurations,
    fetchWeatherIcons,
    fetchConfigurationGroups,
} from '../../../alerts/api/FetchAlerts'
import {WeatherDefinitionDetails} from '../../../../entities/weatherDefinition/ui/WeatherDefinitionDetails'
import {SearchInput} from 'shared/ui/SearchInput'
import ItemsSelectTopBar from '../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import AgreeModal from 'shared/ui/AgreeModal'
import EmptyStateDefinitions from '../../../../shared/ui/emptyStates/EmptyStateDefinitions'
import {useSearchParams} from 'react-router-dom'
import './WeatherDefinitionSetting.css'
import {SeveritySelector} from '../../../../features/weatherDefinition/SeveritySelector'
import ProgressModal from '../../../../shared/ui/ProgressModal'
import {useLocationsStore} from '../../../../app/store/LocationsStore'

export default function WeatherDefinitionSetting({onChange, doupdate, setHideSettingsMenu}) {
    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const [definitions, setDefinitions] = useState([])
    const [openDefinition, setOpenDefinition] = React.useState(null)
    const [icons, setIcons] = useState([])
    const [alertConfigs, setAlertConfigs] = useState([])
    const [configGroups, setConfigGroups] = useState([])
    const [searchString, setSearchString] = useState('')
    const [severity, setSeverity] = useState('All')
    const [update, setUpdate] = useState(0)
    const [toDelete, setToDelete] = useState({})
    const [agreeModal, setAgreeModal] = useState(false)
    const [progressModal, setProgressModal] = useState(false)

    const idsToDelete = Object.entries(toDelete).filter(([key, value])=>value).map(([key, value])=>+key)
    const definitionsToDelete = definitions.filter((definition)=>idsToDelete.includes(definition.id))

    const allDefinitionsSelected = definitions.length > 0 && definitions.every((definition) => toDelete[definition.id])
    const someDefinitionsSelected = Object.values(toDelete).some((val) => val) && !allDefinitionsSelected

    if (doupdate && doupdate()) {
        setTimeout(() => {
            setUpdate(Date.now())
        }, 10)
    }

    const [query] = useSearchParams()
    useEffect(() => {
        fetchWeatherIcons().then((data) => {
            setIcons(data)
        })
        fetchWeatherDefinitions().then((data) => {
            const definitions = data.sort((a, b) => a.name.localeCompare(b.name))
            setDefinitions(definitions)
            const weatherDefinitionArg = query.get('weather_definition_id')
            if (weatherDefinitionArg) {
                const definitionId = parseInt(weatherDefinitionArg)
                const openDefinition = definitions.find( (e) => e.id === definitionId)
                if (openDefinition) {
                    setOpenDefinition(openDefinition)
                }
            }
        })
        fetchLocations()
        fetchAlertConfigurations().then((data) => {
            setAlertConfigs(data)
        })
        fetchConfigurationGroups().then((data) => {
            setConfigGroups(data)
        })
    }, [update])

    useEffect(() => () => {
        const weatherDefinitionArg = query.get('weather_definition_id')
        if (weatherDefinitionArg) {
            const url = new URL(window.location.href)
            url.searchParams.delete('weather_definition_id')
            window.history.pushState({}, null, url)
        }
    }, [])

    const handleSeverityChange = (event) => {
        setSeverity(event.target.value)
    }

    useEffect(() => {

    }, [severity])

    useEffect(() => {
        if (!definitions || !icons) return
        const icons_obj = {}
        icons.forEach((icon) => {
            icons_obj[icon.id] = icon
        })
        const definitions_obj = {}
        definitions.forEach((definition) => {
            definition.icon_url = icons_obj[definition.icon]
            definitions_obj[definition.id] = definition
            definition.configurations = {}
        })
        const locations_obj = {}
        locations.forEach((location) => {
            locations_obj[location.id] = location
        })
        const configs_obj = {}
        alertConfigs.forEach((config) => {
            configs_obj[config.id] = config
        })
        configGroups.forEach((relation) => {
            const config = configs_obj[relation[1]]
            if (!config) return
            const definition = definitions_obj[config.weather_definition_id]
            const location = locations_obj[config.location_id]
            if (!definition || !location) return
            if (!definition.configurations[relation[0]]) definition.configurations[relation[0]] = []
            definition.configurations[relation[0]].push(location)
        })
    }, [definitions, icons, locations, alertConfigs, configGroups])

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim())
    }

    const deleteDefinition = (definition) => {
        if (definition) {
            deleteWeatherDefinition(definition.id).then((data) => {
                if (!data.success) return
                const new_definitions = []
                definitions.forEach((def) => {
                    if (def.id !== definition.id) new_definitions.push(def)
                })
                setDefinitions(new_definitions)
                setOpenDefinition(null)
            })
            setToDelete({})
        } else {
            setAgreeModal(true)
        }
    }

    const onAction = (definition, action, value) => {
        console.log('onAction', action, definition, value)
        if (action === 'edit' && definition.rules && definition.user) {
            onChange('edit_definition', definition); setToDelete({})
        } else if (action === 'duplicate' && definition.rules) {
            const dup_definition = JSON.parse(JSON.stringify(definition))
            dup_definition.id = -1
            dup_definition.name = dup_definition.name + ' Copy'
            onChange('edit_definition', dup_definition)
            setToDelete({})
        } else if (action === 'delete') {
            deleteDefinition(definition)
        } else if (action === 'open') {
            setOpenDefinition(definition); setToDelete({})
        } else if (action === 'edit_configuration') onChange('edit_configuration', definition)
        // else if (action === "select") {
        //     let toDel = toDelete;
        //     toDel[definition.id] = value;
        //     for (let id in toDel) {
        //         if (toDel[id]) {
        //             setToDelete({...toDel});
        //             return;
        //         }
        //     }
        //     setToDelete({});
        // }
        // else if (action === 'selectall') {
        //     if (value) {
        //         definitions.forEach(wd => toDelete[wd.id] = value);
        //         setToDelete({...toDelete});
        //     }
        //     else {
        //         setToDelete({});
        //     }
        // }
        else if (action === 'select') {
            const updatedToDelete = {...toDelete, [definition.id]: value}
            setToDelete(updatedToDelete)
        } else if (action === 'selectall') {
            const newToDelete = {}
            if (value) {
                definitions.forEach((definition) => newToDelete[definition.id] = true)
            }
            setToDelete(newToDelete)
        }
    }

    const deleteWD = (agree) => {
        setAgreeModal(true)
        console.log(definitions)
        if (agree) {
            setProgressModal(true)
            const wd = []
            idsToDelete.forEach((id)=>{
                wd.push(deleteWeatherDefinition(id))
            })
            Promise.all(wd).then((results) => {
                console.log(results)
                setDefinitions(definitions.filter((definition)=>!idsToDelete.includes(definition.id)))
                setToDelete({})
                setProgressModal(false)
            })
        }
        setAgreeModal(false)
    }

    const s = searchString.toLocaleLowerCase()
    return (
        <>
            <Box
                sx={{display: openDefinition?'none':'flex'}}
                className="WeatherDefinitionSettings settings"
            >
                <Box
                    className={'settings-toolbar'}
                    style={{borderBottom: '1px solid var(--palette-grey-100)'}}
                >
                    <h3>
                    Weather definitions
                    </h3>
                    <SearchInput
                        value={searchString}
                        onChange={searchStringChanged}
                        placeholder={'Search'}
                    />

                    <SeveritySelector
                        severity={severity}
                        onChange={handleSeverityChange}
                    />

                    <Button
                        data-cy={'new-definition-button'}
                        variant='contained'
                        sx={{marginLeft: 'auto'}}
                        onClick={() => {
                            onChange('edit_definition', {}); setToDelete({})
                        }}
                    >
Create custom definition
                    </Button>
                </Box>

                {/* <ItemsDelete onAction={onAction} visible={!isEmpty(toDelete)}/> */}
                <ItemsSelectTopBar
                    onAction={onAction}
                    visible={Object.values(toDelete).some((value) => value)}
                    allSelected={allDefinitionsSelected}
                    indeterminate={someDefinitionsSelected}
                />

                {isEmpty(Object.values(definitions))
                    ?<Box sx={{width: '100%', height: '100%'}}>
                        <EmptyStateDefinitions
                            title={'Weather definitions will appear here'}
                            text={'You will see weather definitions for alerts here once they are created'}
                        />
                    </Box>
                    :<Box className={'weatherDefinitionList'}>
                        {definitions.map((wd) => {
                            if ((!s || wd.name.toLocaleLowerCase().indexOf(s) >= 0) && (wd.severity === severity || severity === 'All')) {
                                return (
                                    <WeatherDefinitionLine
                                        definition={wd}
                                        onChange={onAction}
                                        selected={toDelete && toDelete[wd.id]}
                                    />
                                )
                            }
                            return undefined
                        })}
                    </Box>}
            </Box>
            {openDefinition &&
            <WeatherDefinitionDetails
                definition_id={openDefinition.id}
                setHideSettingsMenu={setHideSettingsMenu}
                onChange={() => {
                    setOpenDefinition(null)
                }}
                doAction={onAction}
                editable={true}
            />
            }
            {agreeModal &&
            <AgreeModal
                data={{
                    message: <Box
                        className='column'
                        sx={{alignContent: 'stretch', overflow: 'hidden', '&.MuiBox-root': {width: '100%'}}}
                    >
                        <Typography sx={{fontSize: '18px'}}>
                            {`Are you sure you want delete ${Object.keys(toDelete).length} weather definitions and all alerts configuration linked to them?`}
                        </Typography>
                        <Box sx={{maxHeight: '120px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', overflow: 'auto', gap: '0 16px'}}>
                            {definitionsToDelete.map((definition) => (<span>
•
                                {' '}
                                {definition.name}
                                <br/>
                            </span>))}
                        </Box>
                    </Box>,
                    title: 'Delete multiple weather definitions',
                    agreeMsg: 'Delete',
                    mode: 'deleting',
                    agreeFunc: deleteWD,
                }}
            />
            }
            <ProgressModal visible={progressModal}/>
        </>
    )
}
