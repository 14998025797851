import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Dialog,
    Divider,
    IconButton,
} from '@mui/material'
import CloseIcon from '../assets/icons/Close'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {ReactComponent as ContactsRectangle1} from 'shared/assets/svg/confirmationPopUp/ContactsRectangle.svg'
import {ReactComponent as ContactsRectangle2} from 'shared/assets/svg/confirmationPopUp/ContactsRectangle2.svg'
import {ReactComponent as ContactsRectangle3} from 'shared/assets/svg/confirmationPopUp/ContactsRectangle3.svg'
import {ReactComponent as ContactsPin} from 'shared/assets/svg/confirmationPopUp/ContactsPin.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import UsersThreeIcon from '../assets/icons/UsersThree'
import UserIcon from '../assets/icons/User'

export default function ContactsConfirmationModal({
    contacts,
    agreeFunc,
}) {
    const [isSingleContact] = useState(contacts.length === 1)

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '504px',
                gap: '24px',
                padding: '12px 16px',
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    {`Well done, your ${isSingleContact ? 'contact is' : 'contacts are'} created!`}
                </Box>
                <IconButton
                    variant={'outlined'}
                    onClick={agreeFunc}
                    size={'small'}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </Box>
            <Box style={{position: 'relative', height: '80px', overflow: 'hidden'}}>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: 'absolute', left: '-27px', top: '35px'}}>
                    <ContactsRectangle1/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '8px'}}>
                    <ContactsRectangle2/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '52px'}}>
                    <ContactsRectangle3/>
                </Box>
                <Box sx={{position: 'absolute', right: '134px', top: '4px'}}>
                    <ContactsPin/>
                </Box>
            </Box>
            {isSingleContact ? (
                <Box
                    style={{
                        height: '48px',
                        padding: '8px 16px',
                        border: '1px solid var(--palette-grey-100)',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{fontSize: '16px', fontWeight: '500', color: 'var(--palette-grey-900)'}}>
                        {contacts[0].name}
                    </div>
                    <div style={{fontSize: '16px', fontWeight: '500', color: 'var(--palette-grey-900)'}}>
                        {contacts[0].group}
                    </div>
                </Box>
            ) : (<Box className={'fullWidth'}>
                <Accordion
                    className={'fullWidth'}
                    variant={'light'}
                >
                    <AccordionSummary
                        variant={'light'}
                    >
                        <Box className={'row gap4'}>
                            <div style={{fontSize: '14px', fontWeight: '500', color: 'var(--palette-grey-900)', display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <UsersThreeIcon size={'small'}/>
                                Contacts (
                                {contacts.length}
                                )
                            </div>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails
                        className={'gap8 locationGroup'}
                        variant={'light'}
                    >
                        <Box
                            style={{
                                display: 'flex', gap: '8px', flexWrap: 'wrap', maxHeight: '90px', overflow: 'auto',
                            }}
                        >
                            {contacts?.map((contact) => (
                                <Chip
                                    icon={<UserIcon size={'small'}/>}
                                    label={contact.name}
                                    size={'medium'}
                                    key={contact.id}
                                />
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>)}
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                You can view contact data in the list of Contacts and Users. To manage data for a user, go to their individual settings page.
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <Divider/>
                <Button
                    style={{fontWeight: '400', height: '40px', width: '100%'}}
                    data-cy={'wd-success-creation-okay-button'}
                    onClick={agreeFunc}
                >
                    Got it
                </Button>
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
