import React, {useState} from 'react'
import {Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from '@mui/material'
import {DraggablePaper} from '../../../../shared/ui/DraggablePaper'
import DragIcon from '../../../../shared/assets/icons/Drag'
import MapPinIcon from '../../../../shared/assets/icons/MapPin'
import PenIcon from '../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../shared/assets/icons/Trash'
import CopyTooltip from '../../../../shared/ui/CopyTooltip'
import {useLocationsStore} from '../../../../app/store/LocationsStore'
import AgreeModal from '../../../../shared/ui/AgreeModal'

const SelectLocationModal = ({
    location,
    onSelect = () => {},
    onCancel = () => {},
    onEdit = () => {},
    onDelete = () => {},
    editableLocation = false,
    withoutButtons = false,
}) => {
    const {
        deleteLocationByID,
    } = useLocationsStore((state) => state)

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

    const handleDelete = (agreed) => {
        if (agreed) {
            deleteLocationByID(location.id)
            onDelete()
        }
        setIsOpenDeleteModal(false)
    }

    return (
        <Box>
            <Dialog
                open={true}
                aria-labelledby="draggable-dialog-title"
                PaperComponent={DraggablePaper}
                onClose={onCancel}
            >
                <DialogTitle variant={'draggable'}>
                    <DragIcon size={'small'}/>
                </DialogTitle>
                <DialogContent>
                    <Box
                        style={{
                            width: '432px',
                            paddingTop: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                        }}
                    >
                        <Box style={{display: 'flex', flexDirection: 'column'}}>
                            <Box style={{display: 'flex', alignItems: 'center', gap: '8px', height: '24px'}}>
                                <Box style={{marginTop: '4px'}}>
                                    <MapPinIcon size={'small'}/>
                                </Box>
                                <Box style={{fontWeight: '500', fontSize: '16px', wordBreak: 'break-all'}}>
                                    {location.label}
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    wordBreak: 'break-all',
                                    color: 'var(--palette-grey-900)',
                                }}
                            >
                                {location.address.region?.name}
,
                                {location.address.country?.name}
                            </Box>
                        </Box>
                        {location.geometry_type === 'Point'
                            && <TextField
                                autoComplete={'on'}
                                value={location.coordinates.join(', ')}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <CopyTooltip textToCopy={location.coordinates}/>,
                                }}
                            />
                        }
                        {location.location_group
                            && <Box
                                style={{
                                    width: 'fit-content',
                                    padding: '4px 8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    wordBreak: 'break-all',
                                    color: 'var(--palette-grey-900)',
                                    backgroundColor: 'var(--palette-grey-100)',
                                    borderRadius: '8px',
                                }}
                               >
                                {location.location_group}
                            </Box>
                        }
                    </Box>
                    {!editableLocation && !withoutButtons
                        && <Box
                            className='row'
                            style={{paddingTop: '16px'}}
                           >
                            <Button
                                fullWidth
                                className={'spacer'}
                                variant={'outlined'}
                                color={'secondary'}
                                style={{fontWeight: '500', fontSize: '14px', width: '50%', alignItems: 'self-start'}}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                fullWidth
                                className={'spacer'}
                                style={{fontWeight: '500', fontSize: '14px', width: '50%', alignItems: 'self-start'}}
                                onClick={() => onSelect(location)}
                            >
                                Select
                            </Button>
                        </Box>
                    }
                    {editableLocation && !withoutButtons
                        && <Box
                            className='row'
                            style={{paddingTop: '16px'}}
                           >
                            <Button
                                fullWidth
                                startIcon={<PenIcon size={'small'}/>}
                                className={'spacer'}
                                variant={'outlined'}
                                color={'secondary'}
                                style={{fontWeight: '500', fontSize: '15px', width: '50%'}}
                                onClick={() => onEdit(location)}
                            >
                                Edit
                            </Button>
                            <Button
                                fullWidth
                                startIcon={<TrashIcon size={'small'}/>}
                                className={'spacer'}
                                variant={'contained'}
                                color={'error'}
                                style={{fontWeight: '500', fontSize: '15px', width: '50%'}}
                                onClick={() => setIsOpenDeleteModal(location)}
                            >
                                Remove
                            </Button>
                        </Box>
                    }
                </DialogContent>
            </Dialog>

            {isOpenDeleteModal
                && <AgreeModal
                    data={{
                        message: <Box
                            className='column'
                            sx={{
                                alignContent: 'stretch',
                                overflow: 'hidden',
                                '&.MuiBox-root': {maxWidth: 'fit-content'},
                            }}
                                 >
                            <Typography sx={{fontSize: '18px'}}>
Are you sure you want delete
                                "
                                {location.label}
" saved location?
                            </Typography>
                        </Box>,
                        title: 'Delete location',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: handleDelete,
                    }}
                   />
            }

        </Box>
    )
}

export default SelectLocationModal
