import React from 'react'
import {MenuItem} from '@mui/material'
import {Select} from '../../../../shared/ui/Select'
import {useActionItemsStore} from '../../../../app/store/ActionItemsStore'

const CategorySelector = ({category, onChange}) => {
    const {categories} = useActionItemsStore((state) => state)

    return (
        <Select
            placeholder={''}
            data-cy={'category-filter'}
            defaultValue={category}
            onChange={onChange}
            InputProps={{
                startAdornment: <div
                    className={'paragraph'}
                    style={{color: 'var(--palette-grey-400)'}}
                >
                Category:
                </div>,
            }}
        >
            <MenuItem
                value={'All'}
                data-cy={'urgency-filter-all'}
            >
All
            </MenuItem>
            {categories.map((cat) => (<MenuItem
                key={cat.id}
                value={cat.name}
                data-cy={'urgency-filter-item'}
            >
                {cat.name}
            </MenuItem>))}
        </Select>
    )
}

export default CategorySelector
