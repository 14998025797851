import {isEmpty} from "shared/libs/Utils";

export function formatPqResponse(data) {
    if (!data) {
        return 'Unknown';
    }
    if (data.status >= 400) {
        return 'No data';
    }

    if (isEmpty(data)) {
        return 'No data'
    }

    if (data.value && data.units) {
        return `${data.value} ${data.units}`;
    }

    if (data.value === 0) {
        return data.units ? `0 ${data.units}` : '0';
    }

    if (typeof data.text === 'string' && data.text.trim() === '') {
        return 'No data';
    }

    if (data.text?.toLowerCase() === 'undefined') {
        return 'No data'
    }

    if(data.type && data.text){ //for watches and warnings WL-616
        return data.type
    }

    if (data.text) {
        return data.text;
    }
    if (data.value === 0) {
        return `${data.value} ${data.units !== undefined ? data.units : ''}`;
    }
    return JSON.stringify(data).replace(/"/g, ' ');
}