import React, {useState} from 'react'
import {Box, Button, IconButton} from '@mui/material'
import {ManageLocationScreen} from 'widgets/location/ui/manageLocationScreen/ManageLocationScreen'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import LocationsConfirmationModal from '../../../shared/ui/LocationsConfirmationModal'

export default function EditLocationScreen({
    defaultValue = {},
    selectLocations = false,
    closeOnSave = false,
    onClose = () => {
    },
}) {
    const [lastCreatedLocs, setLastCreatedLocs] = useState([])
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(null)

    const isLocationHasID = () => !!defaultValue?.options?.id

    const locationCreated = (locations) => {
        setLastCreatedLocs(locations)
    }

    const handleComplete = () => {
        setIsOpenSuccessModal(lastCreatedLocs)
    }

    const handleCloseSuccessModal = () => {
        setIsOpenSuccessModal(null)
        onClose(!selectLocations)
    }

    return (
        <Box
            className="EditLocationPage fullHeight"
            sx={{
                overflow: 'hidden',
                flexGrow: '1',
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch',
            }}
        >

            <Box
                className={'toolbar padding'}
                sx={{borderBottom: '1px solid #dbe0ea', justifyContent: 'space-between'}}
            >
                <Box style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                    <IconButton
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => onClose(!selectLocations)}
                    >
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3>
                        {isLocationHasID() ? 'Edit' : 'Add'}
                        {' '}
                        location
                    </h3>
                </Box>
                <Button
                    style={{width: '95px'}}
                    disabled={!lastCreatedLocs || lastCreatedLocs.length < 1}
                    onClick={handleComplete}
                >
Complete
                </Button>
            </Box>

            <Box
                sx={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: '1',
                    padding: '20px',
                    backgroundColor: 'var(--palette-grey-100)',
                }}
            >
                <ManageLocationScreen
                    style={{padding: 0}}
                    showLocations={!isLocationHasID()}
                    selectLocations={selectLocations}
                    defaultLocation={defaultValue}
                    closeOnSave={closeOnSave}
                    onChange={onClose}
                    onLocationCreated={locationCreated}
                />
            </Box>
            {isOpenSuccessModal && <LocationsConfirmationModal
                locations={lastCreatedLocs}
                agreeFunc={handleCloseSuccessModal}
            />}
        </Box>
    )
};
