import {styled} from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'

import theme from 'app/Theme'

const AccordionSummaryUserGroup = styled(AccordionSummary)(() => ({
    background: 'var(--palette-grey-background)',
    border: '1px solid',
    borderColor: 'var(--palette-grey-100)',
    borderRadius: '12px',
    transitionDelay: '150ms',
    transitionProperty: 'border-radius, border-bottom',
    display: 'flex',
    gap: '16px',
    '&.Mui-expanded': {
        borderRadius: '12px 12px 0 0',
        borderBottom: '1px solid transparent',
        transitionDelay: '0ms',
        transitionProperty: 'border-radius, border-bottom',
    },
}))

export default AccordionSummaryUserGroup
