import {Box} from '@mui/system'
import React, {useState, useEffect} from 'react'
import NewContactLine from 'entities/contact/ui/NewContactLine'
import {Button, Typography} from '@mui/material'
import PlusIcon from '../../shared/assets/icons/Plus'

export default function AddContactsForm({contacts, groups, onContactsChange}) {
    const [localContacts, setLocalContacts] = useState(contacts)
    const [createdContactsLength] = useState(contacts.length)

    useEffect(() => {
        addNewContact()
    }, [])

    useEffect(() => {
        onContactsChange(localContacts)
    }, [localContacts, onContactsChange])

    const handleContactChange = (updatedContact) => {
        const newContacts = localContacts.map((contact) =>
            (contact.id === updatedContact.id ? updatedContact : contact),
        )
        setLocalContacts(newContacts)
        onContactsChange(newContacts) // undate parent contacts
    }

    const addNewContact = () => {
        const newContact = {
            id: new Date().getTime(),
            name: '',
            email: '',
            phone: '',
            group: undefined,
        }
        setLocalContacts([...localContacts, newContact])
    }

    const removeContact = (contactId) => {
        setLocalContacts(localContacts.filter((contact) => contact.id !== contactId))
    }

    return (
        <Box
            className={'column gap16 fullWidth fullHeight'}
            sx={{display: 'flex', gap: '24px', padding: '24px 24px 32px 24px'}}
        >
            <Box
                component="form"
                sx={{display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'start'}}
            >
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', borderBottom: '1px solid var(--palette-grey-100)', padding: '8px', width: '1168px'}}>
                    <Typography sx={{color: 'var(--palette-grey-500)', width: '268px', height: '24px'}}>
                        Name
                    </Typography>
                    <Typography sx={{color: 'var(--palette-grey-500)', width: '268px', height: '24px'}}>
                        Email
                    </Typography>
                    <Typography sx={{color: 'var(--palette-grey-500)', width: '268px', height: '24px'}}>
                        Phone number
                    </Typography>
                    <Typography sx={{color: 'var(--palette-grey-500)', width: '268px', height: '24px'}}>
                        User group
                    </Typography>
                </Box>

                {localContacts.map((contact, index) => {
                    if (index >= createdContactsLength) {
                        return (
                            <NewContactLine
                                contact={contact}
                                groups={groups}
                                key={contact.id}
                                onRemove={() => removeContact(contact.id)}
                                onContactChange={handleContactChange}
                            />
                        )
                    }
                    return null
                })}
            </Box>
            <Button
                variant='outlined'
                color={'primary'}
                onClick={addNewContact}
                startIcon={<PlusIcon/>}
                sx={{width: 'max-content'}}
            >
                New contact
            </Button>
        </Box>
    )
}
