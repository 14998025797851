import {BaronStormTracks} from 'features/textProducts'


const AdditionalProducts = [
    {
        "id": -999,
        "name": "Single Site Radar",
        "product_id": "nexrad",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "nexrad",
        "available": true
    },
    {
        "id": -1000,
        "name": "Contoured Super-Res Reflectivity",
        "product_id": "{SSR}-0x0466-1",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0466-1",
        "available": true
    },
    {
        "id": -1001,
        "name": "Non-Contoured Super-Res Reflectivity",
        "product_id": "{SSR}-0x0466-1-N",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0466-1-N",
        "available": true
    },
    {
        "id": -1002,
        "name": "Velocity",
        "product_id": "{SSR}-0x0422-1",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0422-1",
        "available": true
    },
    {
        "id": -1003,
        "name": "Severe Threats",
        "product_id": "{SSR}-0x0265-0",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0265-0",
        "available": true
    },
    {
        "id": -1004,
        "name": "Contoured Damaging Wind",
        "product_id": "{SSR}-0x0575-1",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0575-1",
        "available": true
    },
    {
        "id": -1005,
        "name": "Non-Contoured Damaging Wind",
        "product_id": "{SSR}-0x0575-1-N",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0575-1-N",
        "available": true
    },
    ...Object.values(BaronStormTracks.products),
    {
        id: -1013,
        name: 'Current Conditions',
        product_id: 'metar',
        product_group: 'Map Overlays',
        icon: "/images/icons/radar.svg",
        api_product_code: "metar",
        "available": true
    },
    {
        id: -1014,
        name: 'NHC Tropical Tracks',
        product_id: 'tropical/cone_forecast',
        product_group: 'Map Overlays',
        icon: "/images/icons/radar.svg",
        api_product_code: "nhc_tropical_tracks",
        "available": true
    },
]

export default AdditionalProducts