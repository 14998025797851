import React, {useState} from 'react'
import {Autocomplete, Button, IconButton, TextField} from '@mui/material'
import ModalComponent from '../../../../shared/ui/ModalComponent'
import CloseIcon from '../../../../shared/assets/icons/Close'
import {useActionItemsStore} from '../../../../app/store/ActionItemsStore'

const allUrgency = [
    {label: 'Low', value: 'Low'},
    {label: 'Medium', value: 'Medium'},
    {label: 'High', value: 'High'}]

const ChangeUrgencyModal = ({visible, onChange}) => {
    const {selectedActionItems, editActionItem, clearSelectedActionItems} = useActionItemsStore((state) => state)

    const [chosenUrgency, setChosenUrgency] = useState('Low')

    const handleChangeUrgency = async () => {
        try {
            const promises = selectedActionItems.map(async (ai) => {
                const changedActionItem = {
                    ...ai,
                    urgency: chosenUrgency,
                    category: ai.category.id,
                    weather_definition: ai.weather_definition.id,
                }
                return await editActionItem(changedActionItem)
            })
            await Promise.all(promises)
        } catch (error) {
            console.error('Error during urgency update:', error)
        } finally {
            clearSelectedActionItems()
            onChange()
        }
    }

    return (
        <ModalComponent visible={visible}>
            <div
                style={{
                    width: '472px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        lineHeight: '32px',
                    }}
                >
                    <h3>
Change urgency
                    </h3>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        sx={{height: '32px', padding: '8px'}}
                        onClick={onChange}
                    >
                        <CloseIcon sx={{width: '16px', height: '16px'}}/>
                    </IconButton>
                </div>
                <div style={{fontSize: '16px', fontWeight: '400', lineHeight: '24px'}}>
Select the new urgency level for
                    selected action
                    items
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '4px', width: '100%'}}>
                    <div
                        style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: 'var(--palette-grey-600)',
                        }}
                    >
                        Urgency
                    </div>
                    <Autocomplete
                        options={allUrgency}
                        style={{width: '100%'}}
                        data-cy={'mass-change-urgency-selector'}
                        value={allUrgency.find((option) => option.value === chosenUrgency)}
                        onChange={(event, newValue) => {
                            setChosenUrgency(newValue.value)
                        }}
                        renderInput={(params) => <TextField {...params}/>}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: '12px',
                        alignSelf: 'stretch',
                        height: '40px',
                    }}
                >
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        style={{width: 'fit-content', fontWeight: '400'}}
                        onClick={onChange}
                    >
                        Go back
                    </Button>
                    <Button
                        style={{width: 'fit-content', fontWeight: '400'}}
                        data-cy={'mass-change-urgency-agree-button'}
                        onClick={handleChangeUrgency}
                    >
                        Change urgency
                    </Button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default ChangeUrgencyModal
